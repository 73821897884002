function initShareMenu (data) {
  data = data || {}

  let shareIcon = location.protocol + '//sslstatic.xiaoyusan.com/h5/img/article/claims/claims_1530602595.jpg'
  let shareLink = location.protocol + '//' + location.host + location.pathname

  window.xysJs.registerShare({
    wxFriendShare: {
      title: data.title || '小雨伞保险', // 分享标题
      desc: data.desc || '小雨伞保险特卖平台，定制性价比高的保险', // 分享描述
      pic: data.icon || shareIcon, // 分享图标
      url: data.link || shareLink // 分享链接
    },
    wxTimelineShare: {
      title: data.title || '小雨伞保险', // 分享标题
      desc: data.desc || '小雨伞保险特卖平台，定制性价比高的保险', // 分享描述
      pic: data.icon || shareIcon, // 分享图标
      url: data.link || shareLink // 分享链接
    },
    weiboShare: {
      title: data.title || '小雨伞保险', // 分享标题
      desc: data.desc || '小雨伞保险特卖平台，定制性价比高的保险', // 分享描述
      pic: data.icon || shareIcon, // 分享图标
      url: data.link || shareLink // 分享链接
    },
    smsShare: {
      title: data.title || '小雨伞保险', // 分享标题
      desc: data.desc || '小雨伞保险特卖平台，定制性价比高的保险', // 分享描述
      pic: data.icon || shareIcon, // 分享图标
      url: data.link || shareLink // 分享链接
    }
  })

  let signPackage = data.signPackage
  if (!signPackage || signPackage.length == 0 || !window['wx']) {
    return
  }
  window.wx.config({
    debug: false,
    appId: signPackage['appId'],
    timestamp: signPackage['timestamp'],
    nonceStr: signPackage['nonceStr'],
    signature: signPackage['signature'],
    jsApiList: [
      'showMenuItems',
      'hideMenuItems',
      'onMenuShareTimeline',
      'onMenuShareAppMessage'
    ]
  })
  window.wx.ready(function () {
    console.log('wx ready ok')
    window.wx.showMenuItems({
      menuList: [
        'menuItem:refresh',
        'menuItem:share:appMessage',
        'menuItem:share:timeline',
        'menuItem:profile',
        'menuItem:addContact',
        'menuItem:copyUrl',
        'menuItem:openWithQQBrowser',
        'menuItem:openWithSafari'
      ] // 要显示的菜单项，所有menu项见附录3
    })
    window.wx.hideMenuItems({
      menuList: [
        'menuItem:share:facebook',
        'menuItem:share:QZone',
        'menuItem:originPage',
        'menuItem:readMode'
      ] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
    })

    // 在这里调用 API
    window.wx.onMenuShareTimeline({
      title: data.momentTitle || '小雨伞保险特卖平台，定制性价比高的保险', // 分享标题
      link: data.link || shareLink, // 分享链接
      imgUrl: data.icon || shareIcon, // 分享图标
      success: function () {
        // 用户确认分享后执行的回调函数
        // Util.reportEtag('1.2.1.1')
      },
      cancel: function () {
        // 用户取消分享后执行的回调函数
      }
    })

    window.wx.onMenuShareAppMessage({
      title: data.title || '小雨伞保险', // 分享标题
      desc: data.desc || '小雨伞保险特卖平台，定制性价比高的保险', // 分享描述
      link: data.link || shareLink, // 分享链接
      imgUrl: data.icon || shareIcon, // 分享图标
      type: '', // 分享类型,music、video或link，不填默认为link
      dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
      success: function () {
        // 用户确认分享后执行的回调函数
        // Util.reportEtag('1.2.1.2')
      },
      cancel: function () {
        // 用户取消分享后执行的回调函数
      }
    })
  })
}

// 判断是否支持打开小程序（异步）
function canOpenMiniProgram (callback) {
  window.xysJs.checkJsApi({
    path: '/weixin/openMiniProgram',
    success: function () {
      callback(true)
    },
    fail: function () {
      callback(false)
    }
  })
}

// 打开小程序
function openMiniProgram (originid, path, url) {
  // 如果path为空，url存在，则默认从首页跳到指定url
  // 如果path非空，url不存在，跳到指定路径（目前path只能是首页或webview）
  // 如果path非空，url存在，跳到指定路径（需要做两次encodeURIComponent）
  var jumpPath = '/pages/index/index'
  if (url) {
    jumpPath = jumpPath + '?url=' + encodeURIComponent(encodeURIComponent(url))
  } else if (path) {
    jumpPath = path
  }
  window.xysJs.invoke({
    path: '/weixin/openMiniProgram',
    data: {
      userName: originid, //原始ID
      pagePath: jumpPath,
      type: 0 // 0: 正式环境（默认），1：测试环境， 2：体验环境
    },
    unsupport: function () {
      console.log('openMiniProgram unsupport')
    },
    success: function (data) {
      console.log('data', data)
    },
    fail: function (e) {
      console.log('e', e)
    }
  })
}

export { initShareMenu, canOpenMiniProgram, openMiniProgram }
<template>
  <div  :class="['product-more']" v-show="moreVisible">
    <a :href="moreUrl" class="btn-more" data-track='index_moreInsurance'>
      <span>查看全部<span class="amount">{{category[classId]}}款</span>{{classMap[classId]}}{{newLayout?'':'保险'}}</span>
      <i class="icon-arrow"></i>
    </a>
  </div>
</template>
<script>
export default {
  name: 'More',
  props: {
    category: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    classId:{
      type:Number,
      default:0
    },
    newLayout:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      classMap:{
        1: '少儿',
        2: '成人',
        3: '父母',
        5: '旅行',
        8: '家庭',
        10: '财富保险',
        14: '税优保险'
      }
    }
  },
  computed: {
    moreVisible(){
      if(Number(this.classId) === 0 || !this.category[this.classId] ){
        return false
      }
      return true
    },
    moreUrl () {
      return `/class/itemlist?id=${this.classId}&chn=h5index`
    } 
  }
}
</script>
<template>
  <section class="back-top opacity-will-change" 
  :class="{'transparent':!visible}" 
  :style="`bottom:16rem`"
  @click.stop="toTop"></section>
</template>
<script>
import { scrollToTop } from '@/assets/js/util'
export default {
  name: 'BackTop',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  methods:{
    toTop(){
      scrollToTop({
        callback: () => {
          this.$emit('to',false)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.back-top {
  position: fixed;
  right: 1rem;
  // bottom: 6rem;
  width: 4rem;
  height: 4rem;
  background: url('//sslstatic.xiaoyusan.com/h5/img/m_index/scrollTop.png') no-repeat 50% rgba(0,0,0,.5);
  background-size: 2.5rem 1.5rem;
  border-radius: 50%;
  z-index: 100;
  cursor: pointer;
  img{
    width: 100%;
    height: 100%;
  }
}
.opacity-will-change {
  transition: opacity 0.5s ease-in;
}
.transparent {
  opacity: 0;
}

</style>
<template>
  <div v-if="welfare.img" class="welfare" >
    <a :href="welfare.url" :data-track="welfare.track">
      <img :src="welfare.img" alt="" />
    </a>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Welfare',
  props: {
    bordervisible:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    ...mapState(['newLayout','welfare'])
  }
}
</script>
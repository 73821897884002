var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "adviser" }, [
    _c(
      "a",
      { attrs: { href: _vm.url, "data-track": "index_consultant_banner_new" } },
      [_c("div", { staticClass: "adviser-img" })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLogin
    ? _c("div", { staticClass: "login_layer" }, [
        _c("section", { staticClass: "login_config" }, [
          _c("img", {
            staticClass: "avatar",
            attrs: {
              src: _vm.loginTips.icon || require("@/assets/img/logo.png"),
              alt: ""
            }
          }),
          _c("p", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.loginTips.desc || "新人福利：3万元保额意外保障"))
          ])
        ]),
        _c(
          "section",
          {
            staticClass: "btn",
            attrs: { "data-track": "login_new00" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.showLoginLayer.apply(null, arguments)
              }
            }
          },
          [_vm._v(_vm._s(_vm.loginTips.btn || "立即登录"))]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="concern">
    <div class="concern-box">
      <a href="javascript:;" data-track="detail_follow" class="concern-text">
        获取更多保险特卖福利，<span class="concern-subscribe" @click="showQrCode">点击关注小雨伞公众号 &gt;</span>
      </a>
      <span class="concern-close" @click="close"></span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Concern',
  methods:{
    close(){
      this.$emit('close')
    },
    showQrCode(){
      this.$emit('qrcode',true)
    }
  }
}
</script>
import company1 from '@/assets/img/company/company1.png'
import company2 from '@/assets/img/company/company2.png'
import company3 from '@/assets/img/company/company3.png'
import company4 from '@/assets/img/company/company4.png'
import company5 from '@/assets/img/company/company5.png'
import company6 from '@/assets/img/company/company6.png'
import company7 from '@/assets/img/company/company7.png'
import company8 from '@/assets/img/company/company8.png'
import company9 from '@/assets/img/company/company9.png'
import company10 from '@/assets/img/company/company10.png'
import company11 from '@/assets/img/company/company11.png'
import company12 from '@/assets/img/company/company12.png'

export const companyList = [
    company1,
    company2,
    company3,
    company4,
    company5,
    company6,
    company7,
    company8,
    company9,
    company10,
    company11,
    company12,
]
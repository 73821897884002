var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.moreVisible,
          expression: "moreVisible"
        }
      ],
      class: ["product-more"]
    },
    [
      _c(
        "a",
        {
          staticClass: "btn-more",
          attrs: { href: _vm.moreUrl, "data-track": "index_moreInsurance" }
        },
        [
          _c("span", [
            _vm._v("查看全部"),
            _c("span", { staticClass: "amount" }, [
              _vm._v(_vm._s(_vm.category[_vm.classId]) + "款")
            ]),
            _vm._v(
              _vm._s(_vm.classMap[_vm.classId]) +
                _vm._s(_vm.newLayout ? "" : "保险")
            )
          ]),
          _c("i", { staticClass: "icon-arrow" })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
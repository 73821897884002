var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "contact row" }, [
    _c(
      "a",
      {
        staticClass: "btn-item row",
        attrs: { href: _vm.url, "data-track": "publicInfo" }
      },
      [
        _c("i", { staticClass: "icon icon-info" }),
        _c("span", [_vm._v("信息披露")])
      ]
    ),
    _c(
      "a",
      {
        staticClass: "btn-item row",
        attrs: {
          href: "javascript:;",
          "data-track": "index_webCustomerSevice_new"
        },
        on: { click: _vm.contact }
      },
      [
        _c("i", { staticClass: "icon icon-service" }),
        _c("span", [_vm._v("在线客服")])
      ]
    ),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn-item row",
        attrs: { href: "tel:400-919-7788", "data-track": "index_phone" }
      },
      [
        _c("i", { staticClass: "icon icon-tel" }),
        _c("span", [_vm._v("电话:")]),
        _c("em", [_vm._v("400-919-7788")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
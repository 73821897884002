var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layer ins qrcode_layer" }, [
    _c("div", { staticClass: "layer-box" }, [
      _vm._m(0),
      _c("p", {
        staticClass: "qrcode-close layer-close",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.close.apply(null, arguments)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "layer-content" }, [
      _c("section", { staticClass: "layer-header" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/qrcode-header.png"),
            alt: "小雨伞保险"
          }
        }),
        _c("p", { staticClass: "title" }, [_vm._v("关注 [小雨伞] 公众号")])
      ]),
      _c("div", { staticClass: "layer-img" }, [
        _c("img", {
          attrs: { src: require("@/assets/img/qrcode.png"), alt: "小雨伞保险" }
        })
      ]),
      _c("p", { staticClass: "text" }, [_vm._v("长按识别二维码关注")]),
      _c("p", { staticClass: "text" }, [_vm._v("了解更多保障福利")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
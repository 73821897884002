<template>
  <div ref="wrapper" class="scroll-wrapper">
    <div class="scroll-content">
      <slot></slot>
    </div>
  </div>
</template>
<script >
import BetterScroll from '@/assets/js/BScroll/index'
const COMPONENT_NAME = 'ActScroll'
const DIRECTION_H = 'horizontal'
const DIRECTION_V = 'vertical'

export default {
  name: COMPONENT_NAME,
  props: {
    probeType: {
      type: Number,
      default: 2
    },
    click: {
      type: Boolean,
      default: true
    },
    listenScrollInit: {
      type: Boolean,
      default: false
    },
    listenScroll: {
      type: Boolean,
      default: false
    },
    listenBeforeScroll: {
      type: Boolean,
      default: false
    },
    listenScrollEnd: {
      type: Boolean,
      default: false
    },
    listenZoomEnd: {
      type: Boolean,
      default: false
    },
    listenPullingUp: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: DIRECTION_V
    },
    scrollbar: {
      type: null,
      default: false
    },
    startY: {
      type: Number,
      default: 0
    },
    refreshDelay: {
      type: Number,
      default: 20
    },
    freeScroll: {
      type: Boolean,
      default: false
    },
    mouseWheel: {
      type: Boolean,
      default: false
    },
    bounce: {
      type: [Boolean, Object],
      default: true
    },
    zoom: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      beforePullDown: true,
      isRebounding: false,
      pullUpDirty: true,
      pullDownStyle: '',
      bubbleY: 0,
      lastTouchStart: null,
      fingers: 0,
      tapTarget: null,
      isDouble: false,
      startXd: 0,
      startYd: 0,
      endXd: 0,
      endYd: 0
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initScroll()
    })
  },
  destroyed () {
    this.scroll && this.scroll.destroy()
    this.$emit('destroyed', this.scroll)
  },
  methods: {
    initScroll () {
      if (!this.$refs.wrapper) {
        return
      }

      let options = {
        probeType: this.probeType,
        click: this.click,
        scrollY: this.freeScroll || this.direction === DIRECTION_V,
        scrollX: this.freeScroll || this.direction === DIRECTION_H,
        scrollbar: this.scrollbar,
        startY: this.startY,
        freeScroll: this.freeScroll,
        mouseWheel: this.mouseWheel,
        bounce: this.bounce,
        zoom: this.zoom,
        bounceTime: 300
      }

      this.scroll = new BetterScroll(this.$refs.wrapper, options)
      if (this.listenScroll) {
        this.scroll.on('scroll', (pos) => {
          this.$emit('scroll', pos)
        })
      }

      if (this.listenScrollEnd) {
        this.scroll.on('scrollEnd', (pos) => {
          this.$emit('scroll-end', pos)
        })
      }

      if (this.listenBeforeScroll) {
        this.scroll.on('beforeScrollStart', () => {
          this.$emit('beforeScrollStart')
        })

        this.scroll.on('scrollStart', () => {
          this.$emit('scroll-start')
        })
      }
      if (this.listenZoomEnd) {
        this.scroll.on('zoomEnd', () => {
          this.$emit('zoomEnd', this.scroll)
        })
      }
      if (this.zoom) {
        this.isDouble = true
      }
      if (this.listenScrollInit) {
        this.$emit('scrollSuc', this.scroll)
      }
      if (this.listenPullingUp) {
        this.$emit('pullingUp', this.scroll)
      }
    },
    disable () {
      this.scroll && this.scroll.disable()
    },
    enable () {
      this.scroll && this.scroll.enable()
    },
    refresh () {
      this.scroll && this.scroll.refresh()
    },
    scrollTo () {
      this.scroll && this.scroll.scrollTo.apply(this.scroll, arguments)
    },
    scrollToElement () {
      this.scroll && this.scroll.scrollToElement.apply(this.scroll, arguments)
    },
    clickItem (e, item) {
      this.$emit('click', item)
    },
    destroy () {
      this.scroll.destroy()
    },
    forceUpdate () {
      this.refresh()
    }
  }
}
</script>
<style lang="scss" scoped>
.scroll-wrapper {
  position: relative;
  height: 100%;
  transform: translateZ(0);
  overflow: hidden;
  .scroll-content {
    width: unset;
    position: relative;
    z-index: 1;
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list.length
    ? _c(
        "section",
        {
          staticClass: "promotion js-promotion",
          attrs: { index: "" + _vm.classId }
        },
        [
          _c(
            "article",
            { staticClass: "promotion-box" },
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "div",
                { key: "buttomItem" + index, staticClass: "promotion-item" },
                [
                  _c("h3", { staticClass: "promotion-title" }, [
                    _vm._v(_vm._s(item.title))
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "promotion-link",
                      attrs: { href: _vm.getUrl(item.JumpChainUrl) }
                    },
                    [_c("img", { attrs: { src: item.picUrl, alt: "" } })]
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "top-layer", attrs: { id: "download" } }, [
    _c("a", { staticClass: "layer-content", attrs: { href: _vm.url } }, [
      _c("img", {
        staticClass: "icon",
        attrs: { src: require("@/assets/img/header-icon-new.png"), alt: "" }
      }),
      _vm._m(0)
    ]),
    _c("a", {
      staticClass: "icon-close",
      attrs: { href: "javascript:;" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.close.apply(null, arguments)
        }
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v("下载小雨伞APP，免费领"),
      _c("span", [_vm._v("3万元保额意外保障")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
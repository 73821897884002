import { render, staticRenderFns } from "./company.vue?vue&type=template&id=28da2fc4&scoped=true&"
import script from "./company.vue?vue&type=script&lang=js&"
export * from "./company.vue?vue&type=script&lang=js&"
import style0 from "./company.vue?vue&type=style&index=0&id=28da2fc4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28da2fc4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/alidata/gulpserver/codes/beta-compiler/indexV2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28da2fc4')) {
      api.createRecord('28da2fc4', component.options)
    } else {
      api.reload('28da2fc4', component.options)
    }
    module.hot.accept("./company.vue?vue&type=template&id=28da2fc4&scoped=true&", function () {
      api.rerender('28da2fc4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/company.vue"
export default component.exports
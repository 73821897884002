var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasInit
    ? _c(
        "div",
        { staticClass: "g-container container iphonex_padding" },
        [
          _c("search", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchVisible,
                expression: "searchVisible"
              }
            ],
            ref: "search",
            attrs: {
              topGoods: _vm.topGoods,
              searchWord: _vm.searchWord,
              searchVisible: _vm.searchVisible
            },
            on: { cancel: _vm.cancelSearch }
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.searchVisible && !_vm.questionareVisible,
                  expression: "!searchVisible && !questionareVisible"
                }
              ],
              staticClass: "home-page"
            },
            [
              _vm.downloadLayerVisible
                ? _c("download-layer", {
                    attrs: { url: _vm.downloadUrl },
                    on: { close: _vm.closeDownloadLayer }
                  })
                : _vm._e(),
              _vm.concernVisible
                ? _c("concern", {
                    on: {
                      close: _vm.closeConcern,
                      qrcode: _vm.qrCodeVisibleToggle
                    }
                  })
                : _vm._e(),
              !_vm.bv.isXysApp
                ? _c("header-bar", {
                    ref: "headerBarComponent",
                    attrs: {
                      mt: _vm.downloadLayerVisible ? 3 : 0,
                      showSearchBtn: _vm.showSearchBtn,
                      unread: _vm.unread,
                      searchWord: _vm.searchWord,
                      isSpecialActivity: _vm.isSpecialActivity
                    },
                    on: { search: _vm.searchClicked }
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  ref: "head-content",
                  class: [
                    _vm.isBigEvent
                      ? "head-content-gray"
                      : _vm.isSpecialActivity
                      ? "head-content-special"
                      : "head-content"
                  ],
                  attrs: { id: "head-content" }
                },
                [
                  _c("div", { staticClass: "header-bottomColor" }),
                  _c("banner", { attrs: { ssr: _vm.ssrRender } }),
                  _c("entry", {
                    attrs: { isSpecialActivity: _vm.isSpecialActivity }
                  }),
                  _c("welfare")
                ],
                1
              ),
              _c("div", {
                ref: "navTop",
                staticClass: "nav-top",
                style:
                  "position: relative;top: -" +
                  (_vm.downloadLayerVisible ? 7.8 : 4.8) +
                  "rem"
              }),
              _c("product-nav", {
                ref: "navComponent",
                attrs: {
                  list: _vm.list,
                  active: _vm.active,
                  border: _vm.navBorder,
                  navTop: 4.8,
                  downloadHeight: _vm.downloadLayerVisible ? 3 : 0
                },
                on: { change: _vm.changeNav }
              }),
              _vm.newLayout
                ? _c("productFinance", { attrs: { list: _vm.productList } })
                : _c("product", {
                    attrs: { list: _vm.productList, classId: _vm.classId }
                  }),
              _c("more", {
                attrs: {
                  category: _vm.categoryNum,
                  classId: _vm.classId,
                  newLayout: _vm.newLayout
                }
              }),
              _vm.newLayout
                ? _c("promotion", {
                    attrs: { list: _vm.bottomBanner, classId: _vm.classId }
                  })
                : _c("adviser", { attrs: { url: _vm.adviserUrl } }),
              _c("claim", { attrs: { url: _vm.claimUrl } }),
              _c("company"),
              _c("contact", { attrs: { url: _vm.contactUrl } }),
              _c("back-top", {
                attrs: { visible: _vm.backTopVisible, isLogin: _vm.isLogin },
                on: { to: _vm.fadeInOrOut }
              }),
              _vm.activityBtnVisible
                ? _c("activity-btn", { attrs: { data: _vm.activityData } })
                : _vm._e(),
              _vm.popLayerVisible
                ? _c("pop-layer", {
                    attrs: { data: _vm.popLayerData },
                    on: {
                      layerclick: _vm.layerClick,
                      close: _vm.layerCloseClick
                    }
                  })
                : _vm._e(),
              _vm.qrCodeVisible
                ? _c("qrcode", {
                    on: {
                      close: function($event) {
                        return _vm.qrCodeVisibleToggle(false)
                      }
                    }
                  })
                : _vm._e(),
              !_vm.ssrRender
                ? _c("login-layer", {
                    ref: "loginLayer",
                    attrs: {
                      isLogin: _vm.isLogin,
                      loginTips: _vm.loginTips,
                      isWx: _vm.bv.isWx
                    },
                    on: { success: _vm.loginSuccess }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.questionareVisible
            ? _c("questionare", { on: { fill: _vm.initPage } })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="layer ins qrcode_layer">
    <div class="layer-box">
      <div class="layer-content">
        <section class="layer-header">
          <img src="@/assets/img/qrcode-header.png" alt="小雨伞保险" />
          <p class="title">关注 [小雨伞] 公众号</p>
        </section>
        <div class="layer-img">
          <img src="@/assets/img/qrcode.png" alt="小雨伞保险"/>
        </div>
        <p class="text">长按识别二维码关注</p>
        <p class="text">了解更多保障福利</p>
      </div>
      <p class="qrcode-close layer-close" @click.stop="close"></p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Qrcode',
  methods:{
    close(){
      this.$emit('close')
    }
  }
}
</script>
<template>
  <!-- 浮标 -->
  <a class="activity_btn" :href="data.url">
    <img :src="data.src"  alt="">
  </a>
</template>
<script>
export default {
  name: 'Welfare',
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  }
}
</script>
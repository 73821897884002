<template>
  <section class="adviser">
    <a :href="url" data-track="index_consultant_banner_new">
      <div class="adviser-img"></div>
    </a>
  </section>
</template>
<script>

export default {
  name: 'Adviser',
  props:{
    url:{
      type:String,
      default:''
    }
  }
}
</script>


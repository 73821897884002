<template>
  <header class="top-layer" id="download">
    <a :href="url" class="layer-content">
      <img src="@/assets/img/header-icon-new.png" alt="" class="icon" />
      <div class="text">下载小雨伞APP，免费领<span>3万元保额意外保障</span></div>
    </a>
    <a href="javascript:;" class="icon-close" @click.stop="close"></a>
  </header>
</template>
<script>
export default {
  name: 'TopLayer',
  props:{
    url:{
      type:String,
      default:''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
<template>
  <section class="promotion js-promotion" :index="`${classId}`" v-if="list.length">
    <article class="promotion-box">
      <div class="promotion-item" v-for="(item,index) in list" :key="`buttomItem${index}`">
        <h3 class="promotion-title">{{item.title}}</h3>
        <a :href="getUrl(item.JumpChainUrl)" class="promotion-link">
          <img :src="item.picUrl" alt="">
        </a>
      </div> 
    </article>
  </section>
</template>
<script>
import { mapGetters } from "vuex"
import { changeChn } from "@/assets/js/util"
export default {
  name: 'Promotion',
  props: {
    list:{
      type:Array,
      default:()=>{
        return []
      }
    },
    classId:{
      type:Number,
      default:0
    }
  },
  computed: {
    ...mapGetters(["chnMap"]),
  },
  methods:{
    getUrl (url) {
      return changeChn(url, this.chnMap)
    }
  }
}
</script>


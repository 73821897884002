var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layer" }, [
    _c("div", { staticClass: "activity" }, [
      _c(
        "section",
        {
          staticClass: "activity_close",
          attrs: { "data-track": _vm.data.closeTrack },
          on: { click: _vm.close }
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/img/icon/icon-close.png") }
          })
        ]
      ),
      _c(
        "a",
        {
          attrs: {
            href: _vm.data.url || "javascript:;",
            "data-track": _vm.data.layerTrack
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.layerClick.apply(null, arguments)
            }
          }
        },
        [
          _c("img", {
            staticClass: "activity_img",
            attrs: { src: _vm.data.src, alt: "" }
          })
        ]
      ),
      _vm.data.birthdayVisible
        ? _c("div", { staticClass: "birthday" }, [
            _c("div", { staticClass: "avatar" }, [
              _c("img", { attrs: { alt: "", src: _vm.data.headurl } })
            ]),
            _c("div", { staticClass: "text" }, [
              _c("div", [
                _vm._v("亲爱的"),
                _c("span", [_vm._v(_vm._s(_vm.data.level))]),
                _vm._v("会员")
              ]),
              _c("div", [
                _vm._v("今天是"),
                _c("span", { staticClass: "truename" }, [
                  _vm._v(_vm._s(_vm.data.truename))
                ]),
                _vm._v("的生日")
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
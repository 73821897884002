<template>
  <div class="g-container container iphonex_padding" v-if="hasInit">
    <search ref="search" :topGoods="topGoods" :searchWord="searchWord" :searchVisible="searchVisible" v-show="searchVisible" @cancel="cancelSearch" />
    <div v-show="!searchVisible && !questionareVisible" class="home-page">
      <download-layer :url="downloadUrl" v-if="downloadLayerVisible" @close="closeDownloadLayer" />
      <concern
        v-if="concernVisible"
        @close="closeConcern"
        @qrcode="qrCodeVisibleToggle"
      />
      <header-bar
        v-if="!bv.isXysApp"
        ref="headerBarComponent"
        :mt="downloadLayerVisible ? 3 : 0"
        :showSearchBtn="showSearchBtn"
        :unread="unread"
        :searchWord="searchWord"
        :isSpecialActivity="isSpecialActivity"
        @search="searchClicked"
      />
      <div :class="[isBigEvent ? 'head-content-gray': isSpecialActivity ? 'head-content-special' : 'head-content']"  id="head-content" ref="head-content">
        <div class="header-bottomColor"></div>
        <banner :ssr="ssrRender"/>
        <entry :isSpecialActivity="isSpecialActivity" />
        <welfare />
      </div>
      <div class="nav-top" :style="`position: relative;top: -${downloadLayerVisible ? 7.8 : 4.8}rem`" ref="navTop"></div>
      <product-nav
        ref="navComponent"
        :list="list"
        :active="active"
        :border="navBorder"
        :navTop="4.8"
        :downloadHeight="downloadLayerVisible ? 3 : 0"
        @change="changeNav"
      />
      <productFinance :list="productList" v-if="newLayout" />
      <product :list="productList" :classId="classId" v-else />
      <more :category="categoryNum" :classId="classId" :newLayout="newLayout" />
      <promotion :list="bottomBanner" :classId="classId" v-if="newLayout" />
      <adviser :url="adviserUrl" v-else />
      <claim :url="claimUrl" />
      <company />
      <contact :url="contactUrl"/>
      <back-top
        :visible="backTopVisible"
        :isLogin="isLogin"
        @to="fadeInOrOut"
      />
      <activity-btn v-if="activityBtnVisible" :data="activityData" />
      <pop-layer
        v-if="popLayerVisible"
        :data="popLayerData"
        @layerclick="layerClick"
        @close="layerCloseClick"
      />
      <qrcode v-if="qrCodeVisible" @close="qrCodeVisibleToggle(false)" />
      <!-- <finance-banner v-if="isLogin && showFinanceBanner" @close="handleFinanceBannerClose" @hasShow="hasShowFinanceBanner = true" /> -->
      <login-layer
        v-if="!ssrRender"
        ref="loginLayer"
        :isLogin="isLogin"
        :loginTips="loginTips"
        :isWx="bv.isWx"
        @success="loginSuccess"
      />
    </div>
    <questionare v-if="questionareVisible" @fill="initPage" />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import downloadLayer from "./components/downloadLayer.vue";
import concern from "./components/concern.vue";
import qrcode from "./components/qrcode.vue";
import headerBar from "./components/headerBar.vue";
import search from "./components/search.vue";
import banner from "./components/banner.vue";
import entry from "./components/entry.vue";
import welfare from "./components/welfare.vue";
import productNav from "./components/productNav.vue";
import product from "./components/product.vue";
import productFinance from "./components/productFinance.vue";
import more from "./components/more.vue";
import adviser from "./components/adviser.vue";
import promotion from "./components/promotion.vue";
import claim from "./components/claim.vue";
import company from "./components/company.vue";
import contact from "./components/contact.vue";
import backTop from "./common/backTop.vue";
import activityBtn from "./components/activityBtn.vue";
import popLayer from "./components/popLayer.vue";
import questionare from "./components/questionare.vue";
import LoginLayer from "./components/loginlayer.vue";
// import FinanceBanner from "./components/financeBanner.vue";
import birthdayPic from "@/assets/img/birthday.png";
import avatar from "@/assets/img/avatar.png";
import { initShareMenu } from "../assets/js/weixin.js";
import { getCookie, setCookie, changeChn, throttle } from "@/assets/js/util";
// import { firstScreen ,indexData} from '@/views/mock'

export default {
  name: "Index",
  components: {
    downloadLayer,
    concern,
    qrcode,
    headerBar,
    search,
    banner,
    entry,
    welfare,
    productNav,
    product,
    productFinance,
    more,
    adviser,
    promotion,
    claim,
    company,
    contact,
    backTop,
    activityBtn,
    popLayer,
    questionare,
    LoginLayer
    // FinanceBanner
  },
  data() {
    return {
      loginTips: {
        icon: "",
        desc: "",
        btn: "",
      },
      isLogin: false,
      ssrRender: true,
      downloadLayerVisible: false, // 下载app
      concernVisible: false,
      qrCodeVisible: false,
      showSearchBtn: false, // 搜索
      unread: false,
      searchVisible: false,
      indexBanner: "",
      categoryNum: {},
      // 导航
      active: 0,
      backTopVisible: false, // 返回顶部
      fixedNavTop: 0,
      navBorder: false,
      // activity and poplayer
      activityBtnVisible: false,
      popLayerVisible: false,
      activityData: {
        url: "",
        src: "",
      },
      popLayerData: {
        url: "",
        src: "",
        sign: "",
        layerTrack: "",
        closeTrack: "",
      },
      downloadUrl: "/static/html/app_tongji.html?source=h5",
      adviserUrl: "/shk/wkpage/index/142e59_2023001553.1.html?eva=2023001553&chn=cg-dibu",
      claimUrl: "/claims/guidev2#/brand",
      contactUrl: "/static/html/publicInfo_landing.html#/h5?tab=1",
      searchWord: {},
      topGoods: {},
      // showFinanceBanner: false,
      // hasShowFinanceBanner: false,
      // keepHideFinanceBanner: false
    }
  },
  computed: {
    ...mapState(["list", "questionareVisible", "hasInit", "chn", "pageChn", "subChn", 'isBigEvent', 'isSpecialActivity']),
    ...mapGetters(["chnMap", "chnSuffix"]),
    productMap() {
      let m = {};
      this.list.forEach((item, index) => {
        m[String(item.class)] = {
          index: index,
          productList: item.productList || [],
        };
      });
      return m;
    },
    classId() {
      if (this.ssrRender) {
        return 0;
      }
      return this.list[Number(this.active)].class
        ? Number(this.list[Number(this.active)].class)
        : 0;
    },
    newLayout() {
      // 财富保险
      return this.list[this.active] && (+this.list[this.active].newLayout === 1);
    },
    productList() {
      if (this.newLayout) {
        return (this.list.length && this.list[this.active].classify) || [];
      }
      return (this.list.length && this.list[this.active].productList) || [];
    },
    bottomBanner() {
      if (this.newLayout) {
        return (this.list.length && this.list[this.active].bottomBanner) || [];
      }
      return [];
    },
    bv() {
      return this.$$browser;
    },
    platformType() {
      if (this.bv.isXysApp) {
        return 3;
      } else if (this.bv.isWx) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  methods: {
    ...mapMutations(["updateState"]),
    ...mapActions(["getFirstScreen"]),
    // 初始化下载app顶部浮条
    initDownloadLayer() {
      let topLayer = getCookie("topLayer") || "0"; // （0没有，1已显示过）
      const isAliMiniProgram = /AlipayClient.*MiniProgram$/i.test(
        this.$$browser.userAgent
      );
      this.downloadLayerVisible =
        this.$$browser.isMobile &&
        !this.$$browser.isWx &&
        !this.$$browser.isApp &&
        !isAliMiniProgram &&
        String(topLayer) !== "1";
    },
    closeDownloadLayer() {
      this.downloadLayerVisible = false;
      setCookie("topLayer", "1");
    },
    initConcern({ isLogin = 0, isWx = 0, isSubscribe = false }) {
      if (isLogin && isWx && !isSubscribe) {
        // 未关注微信公众号用户
        let deadTime = localStorage.getItem("index_follow_qrcode");
        if (deadTime && +new Date() < +new Date(deadTime)) {
          return;
        }
        this.concernVisible = true;
      }
    },
    qrCodeVisibleToggle(v = false) {
      this.qrCodeVisible = v;
    },
    closeConcern() {
      this.concernVisible = false;
      let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      let year = tomorrow.getFullYear();
      let month =
        tomorrow.getMonth() + 1 > 9
          ? tomorrow.getMonth() + 1
          : `0${tomorrow.getMonth() + 1}`;
      let date =
        tomorrow.getDate() > 9 ? tomorrow.getDate() : `0${tomorrow.getDate()}`;
      localStorage.setItem(
        "index_follow_qrcode",
        `${year}-${month}-${date} 00:00`
      );
    },
    initSearchWord(){
      this.$$fetch({ 
        url: '/index/getSearchHotWord',
        method: 'get',
      }).then(res => {
        const { ret, data, msg } = res || {}
        if (Number(ret) === 0) {
          this.searchWord = data || {}
        } else {
          throw new Error(msg)
        }
      }).catch(e => {
        console.log(e)
      })
    },
    initSearch(showSearchBtn) {
      if (!showSearchBtn) {
        return false;
      }
      let clickSearch = localStorage.getItem("clickSearch");
      if (!clickSearch) {
        this.unread = true;
      }
      this.showSearchBtn = true;
      // if (this.$refs.search) {
      //   this.$refs.search.init();
      // }
    },
    initTopGoods () {
      this.$$fetch({ 
        url: '/index/topGoodsV2',
        method: 'get',
      }).then(res => {
        const { ret, data = {}, msg } = res
        if (Number(ret) === 0) {
          const newData = {};
          for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key) && data[key] && data[key].length) {
              newData[key] = data[key].map((item) => ({
                ...item,
                src: changeChn(item.src, {
                  urlChn: `${this.chn}${this.chn ? '_' : ''}search2022`,
                })
              }))
            }
          }
          this.topGoods = newData
        } else {
          throw new Error(msg)
        }
      }).catch(e => {
        console.log(e)
      })
    },
    searchClicked() {
      this.searchVisible = true;
      localStorage.setItem("clickSearch", "8");
      this.unread = false;
    },
    cancelSearch() {
      this.searchVisible = false;
    },
    changeNav(index) {
      this.active = index;
      if (this.backTopVisible) {
        this.$refs["navTop"].scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start", // 上边框与视窗顶部平齐, 默认值
        });
      }
      // if (this.list[this.active] && this.list[this.active].class === 10) {
      //   if (!this.showFinanceBanner && !this.keepHideFinanceBanner) {
      //     this.showFinanceBanner = true
      //   }
      // } else {
      //   if (!this.hasShowFinanceBanner) {
      //     this.showFinanceBanner = false
      //   }
      // }
      this.storageClassId();
    },
    storageClassId() {
      let url = location.href;
      let st = document.documentElement.scrollTop || document.body.scrollTop;
      url = location.hash === "" ? url : url.replace(location.hash, "");
      url = url + "#hp=" + this.classId;
      history.replaceState({ st: st }, document.title, url);
    },
    initActive() {
      if (location.hash !== "") {
        let arr = location.hash.split("&");
        let classId = arr[0].substr(arr[0].indexOf("=") + 1);
        this.active = this.productMap[classId]
          ? this.productMap[classId].index
          : 0;
        // if (this.list[this.active] && this.list[this.active].class === 10 && !this.showFinanceBanner && !this.keepHideFinanceBanner) {
        //   this.showFinanceBanner = true
        // }
      }
    },
    getProductDataMap(productList) {
      let productDataMap = {};
      for (let item of productList) {
        let classId =
          item.insuranceClassify && item.insuranceClassify.id != null
            ? item.insuranceClassify.id
            : "";
        let key = `${item.productId || ""}+${classId}+${item.src || ""}`;
        productDataMap[key] = item;
      }
      return productDataMap;
    },
    initProductList(list) {
      if (!list.length) {
        return false;
      }
      list.forEach((pItem) => {
        if (pItem.productList && pItem.productList.length) {
          let newLayout = pItem.newLayout;
          if (newLayout) {
            pItem.classify.forEach((item) => {
              if (item.productList && item.productList.length) {
                item.productList.forEach((fItem) => {
                  let { src, tagList } = this.handleItemInfo(fItem, true);
                  fItem.src = src;
                  fItem.tagList = tagList;
                });
              }
            });
          } else {
            pItem.productList.forEach((item) => {
              let { src, tagList } = this.handleItemInfo(item, false);
              item.src = src;
              item.tagList = tagList;
            });
          }
        }
      });
      this.$store.commit("updateState", { key: "list", value: list });
    },
    handleTagList(itemInfo, newLayout = false) {
      let tagList = [];
      //一日赔 闪赔
      if (itemInfo.supportTags && Array.isArray(itemInfo.supportTags)) {
        for (let i = 0; i < itemInfo.supportTags.length; i++) {
          if (itemInfo.supportTags[i] === "quickClaims") {
            tagList.push({ text: "闪赔", class: "yellow" })
          }
          if (itemInfo.supportTags[i] === "oneDayClaims") {
            tagList.push({ text: "快速理赔", class: "yellow" })
          }
        }
      }
      // 限时活动 新逻辑
      if (itemInfo.displayActivity && Number(itemInfo.displayActivity) === 1) {
        tagList.push({ text: "限时活动", class: "red" });
      }
      // 静静
      if (itemInfo.jingJingRecommendData) {
        tagList.push({ text: "静静推荐", class: "yellow" });
      }
      // 人工核保和智能核保
      if (itemInfo.intelver) {
        tagList.push({ text: "智能核保", class: "yellow" });
      } else if (itemInfo.healthConfirm) {
        tagList.push({ text: "人工核保", class: "yellow" });
      }
      if (newLayout) {
        // 处理taglist
        if (itemInfo.tagList && itemInfo.tagList.length) {
          for (let tagItem of itemInfo.tagList) {
            tagList.push({ text: tagItem.tag, class: "" });
          }
        }
        // 3级分类才只显示前3个标签
        const showCommentTip =
          itemInfo.commentTip && itemInfo.commentTip >= 100;
        const num = showCommentTip ? 2 : 3;
        tagList = tagList.slice(0, num);
      }
      return tagList;
    },
    handleItemInfo(itemInfo, newLayout = false) {
      let tagList = this.handleTagList(itemInfo, newLayout);
      let src = itemInfo.src;
      if (itemInfo.src) {
        if (Number(itemInfo.recommendForNew) === 1) {
          let sub = this.subChn ? `_${this.subChn}` : "";
          src = changeChn(itemInfo.src, {
            urlChn : this.chn, 
            pageChn: this.pageChn,
            subChn : `new_user${sub}`
          });
        } else {
          src = changeChn(itemInfo.src, this.chnMap);
        }
      }
      return {
        src,
        tagList,
      };
    },
    // 弹窗&浮标
    initActivityPopLayer() {
      const lastTime = getCookie("last_activity_pop_time") || "";
      let dailyActivityPopNum =
        Number(getCookie("daily_activity_pop_num")) || 0;

      if (lastTime) {
        let dailyActivityPopTime = Math.floor(lastTime / 1000 / 86400);
        let curtime = Math.floor(new Date().getTime() / 1000 / 86400);
        if (curtime > dailyActivityPopTime) {
          dailyActivityPopNum = 0;
        }
      } else {
        dailyActivityPopNum = 0;
      }
      // 如果当前已经弹出两次，就不需要再弹出任何弹窗
      if (dailyActivityPopNum >= 2) {
        return false;
      }
      setCookie("daily_activity_pop_num", dailyActivityPopNum);
      Promise.all([this.indexPopup(), this.initBirthDayPop()]).then(
        ([activityList, birthDayData]) => {
          this.renderPop(activityList, birthDayData);
        }
      );
    },
    indexPopup() {
      return this.$$fetch({
        url: `/index/indexPopup`,
        data: {
          platformType: this.platformType,
        },
      })
        .then((res) => {
          if (Number(res.ret) === 0) {
            return res.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log("indexPopup", e);
          return [];
        });
    },
    initBirthDayPop() {
      return this.$$fetch({
        url: "/Birthday/indexPop",
        methods: "POST",
      })
        .then((res) => {
          // const d = {
          //   begTime: 1625021599,
          //   headurl: "",
          //   level: 0,
          //   sign: "02d9df914b90510564b8af21a2b072a2",
          //   truename: "崔海",
          //   url: "https://beta.xinhulu.com/act/vipbirthday/index"
          // }
          // return d
          if (Number(res.ret) === 0) {
            return res.data;
          } else {
            return null;
          }
        })
        .catch((e) => {
          console.log("initBirthDayPop", e);
          return null;
        });
    },
    renderPop(activityList, birthdayData = null) {
      /**
       * 渠道包排序第一
       * 新人弹窗排序第二
       * 最后按order排序
       */
      activityList.sort((a, b) => {
        if (Number(a.businessType) >= 99) return -1;
        if (a.needChn && Number(a.businessType) === 3) return -1;
        if (b.needChn && Number(b.businessType) === 3) return 1;
        if (Number(a.businessType) === 2) return -1;
        if (Number(b.businessType) === 2) return 1;
        return Number(a.order) > Number(b.order) ? -1 : 1;
      });
      // 生日弹窗
      if (birthdayData) {
        let bdData = {
          extra: true,
          begTime: birthdayData.begTime,
          businessType: "1",
          endTime: "",
          pic: birthdayPic,
          platformType: ["1", "3", "4"],
          sign: birthdayData.sign,
          url: birthdayData.url,
          userType: "0",
          truename: birthdayData.truename,
          level: birthdayData.level,
          headurl: birthdayData.headurl,
        };
        activityList.push(bdData);
      }
      for (let item of activityList) {
        if (this.showLayerPopup(item)) {
          item.url = changeChn(item.url, this.chnMap)
          if (item.pic) {
            const popLayerData = {};
            popLayerData.url = item.url || "";
            popLayerData.src = item.pic;
            popLayerData.sign = item.sign;
            let isRenewalLayer = Number(item.businessType) >= 99;
            if (isRenewalLayer) {
              window._xystj &&
                window._xystj.trackEvent("hailey_renew_display", "display");
            }
            popLayerData.layerTrack = isRenewalLayer
              ? "hailey_renew"
              : "window_new";
            popLayerData.closeTrack = isRenewalLayer
              ? "hailey_renew_close"
              : "";
            if (item.extra) {
              popLayerData.truename = item.truename || "";
              popLayerData.level = `V${item.level}`;
              popLayerData.headurl = item.headurl || avatar;
              popLayerData.birthdayVisible = true;
            }

            const num = getCookie("daily_activity_pop_num") || 0;
            setCookie("daily_activity_pop_num", `${Number(num) + 1}`);
            setCookie("last_activity_pop_time", Date.now());
            // 首页弹窗增加曝光量统计
            window._xystj &&
              window._xystj.trackEvent(`home_tc${item.sign || ""}`, "browse");
            this.popLayerData = popLayerData;
            this.popLayerVisible = true;
          }
          if (item.iconImg) {
            const activityData = {};
            activityData.url = item.iconUrl || "";
            activityData.src = item.iconImg || "";
            this.activityData = activityData;
            this.activityBtnVisible = true;
          }
          break;
        }
      }
    },
    /* 判断当前弹窗是否应该被弹出 */
    showLayerPopup(popupData) {
      const curTime = new Date().getTime();
      const startTime = new Date(popupData.begTime * 1000).getTime();
      const endTime =
        popupData.endTime === ""
          ? 32503688472000
          : new Date(popupData.endTime * 1000).getTime();
      const chn = this.$$browser.userAgent.match(
        /MucXiaoyusan.*AndroidMarket\/(.*)$/i
      );
      const onTime = curTime >= startTime && curTime < endTime;
      const onTrueChn =
        !popupData.needChn ||
        (popupData.needChn && chn && chn[1] == popupData.needChn);
      return (
        onTime && onTrueChn && !getCookie(`activity_poster_${popupData.sign}`)
      );
    },
    layerClick() {
      if (this.popLayerData.url) {
        setCookie(`activity_poster_${this.popLayerData.sign}`, "1");
        setTimeout(() => {
          this.popLayerVisible = false;
        }, 1000);
      }
    },
    layerCloseClick() {
      setCookie(`activity_poster_${this.popLayerData.sign}`, "1");
      this.popLayerVisible = false;
    },
    pageScrollEvent() {
      // 返回页面滚动
      let pageYOffset = window.pageYOffset;
      if (pageYOffset > 250) {
        // 返回顶部
        this.backTopVisible = true;
      } else {
        this.backTopVisible = false;
      }
      // this.computedNavSticky()
      // nav borerbottom 的显示与隐藏
      // if (pageYOffset > this.fixedNavTop) {
      //   this.navBorder = true;
      // } else {
      //   this.navBorder = false;
      // }
    },
    fadeInOrOut(visible) {
      this.backTopVisible = visible;
    },
    initWxShare(sign) {
      initShareMenu({
        title: "小雨伞-保险特卖平台",
        momentTitile:
          "小雨伞保险特卖平台，为家庭支柱、子女、父母定制性价比高的保险",
        desc: "为家庭支柱、子女、父母定制性价比高的保险",
        signPackage: sign,
      });
    },
    getIndexData(isShowLoginLayer = false) {
      this.$$fetch({
        url: "/index/GetIndexDataV2",
      })
        .then((res) => {
          this.handleIndexData(res, isShowLoginLayer);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleIndexData(res, isShowLoginLayer = false) {
      const { ret, data, msg } = res || {};
      if (Number(ret) === 0) {
        this.ssrRender = false;
        if (isShowLoginLayer) {
          setTimeout(() => {
            if (this.$refs.loginLayer) {
              this.$refs.loginLayer.showLoginLayer();
            } else {
              const url = 
                location.protocol +
                "//" +
                location.host +
                location.pathname +
                location.search +
                location.hash;
              location.href =
                "/user/login_proxyv2?returl=" + encodeURIComponent(url);
            }
          }, 0);
        }
        this.$store.commit("updateState", { key: "isNew", value: data.isNew });
        const bgGray = data.bigEvent ? (data.bigEvent.bg_gray || 0) : 0
        this.$store.commit("updateState", {
          key: "isBigEvent",
          value: !!bgGray && Number(bgGray) === 1
        })
        let indexBanner = data.indexBanner || []
        let indexRecIconNew = data.indexRecIconNew || []
        if(indexBanner.length) {
          this.$store.commit("updateState", {
            key: "indexBanner",
            value: indexBanner
          })
        }
        if(indexRecIconNew.length){
          for (let item of indexRecIconNew) {
            item.url = changeChn(item.url, this.chnMap)
          }
          this.$store.commit("updateState", {
            key: "indexRecIconNew",
            value: indexRecIconNew || []
          })
        }
        if(data.welfare){
          this.$store.commit('initNewWelfare', data.welfare)
        }
        this.initSearchWord()
        this.initSearch(data.showSearchBtn);
        if (data.isSpecialActivity) {
          this.$store.commit("updateState", {
            key: "isSpecialActivity",
            value: true
          })
        }
        this.initTopGoods()
        this.initProductList(data.productList || []);
        this.categoryNum = data.categoryNum || {};
        this.initActive();
        if (!data.iOSReview) {
          // ios不在审核状态
          this.initDownloadLayer();
          this.initActivityPopLayer();
        }
        if (data.isLogin) {
          // 已登录
          this.isLogin = true;
        } else if (data.loginTips) {
          this.loginTips = data.loginTips;
        }
        if(this.chnSuffix) {
          this.downloadUrl = `${this.downloadUrl}?${this.chnSuffix}`
          this.claimUrl = `${this.claimUrl}?${this.chnSuffix}`
          this.contactUrl = `${this.contactUrl}&${this.chnSuffix}`
          this.adviserUrl = `${this.adviserUrl}?${this.chnSuffix}`
          
        }
        this.initConcern(data);
        this.initWxShare(data.sign);
      } else {
        throw new Error(msg);
      }
    },
    // computedNavSticky(){
    //   if(this.$refs.headerBarComponent && this.$refs.navComponent){
    //     let navComponent =  this.$refs.navComponent.getRect(); 
    //     let headerBarComponent = this.$refs.headerBarComponent.getRect();
    //     //根据headerBar的距离和nav的距离进行比较判断是否吸顶
    //     if(navComponent.top - headerBarComponent.top <= headerBarComponent.height + 0.5){ //加0.5是为了兼容一下
    //       this.isNavSticky = true
    //     }else {
    //       this.isNavSticky = false
    //     }
    //   }
    // },
    async initPage(isShowLoginLayer = false) {
      document.getElementsByClassName("fixed-nav")[0].style.display = "block";
      await this.getIndexData(isShowLoginLayer);
      // nav距离顶部高度
      // if (this.$refs.navComponent) {
      //   let rect = this.$refs.navComponent.getRect();
      //   let doc = document.documentElement;
      //   this.fixedNavTop =
      //     rect.top +
      //     (window.pageYOffset || doc.scrollTop) -
      //     (doc.clientTop || 0);
      // }
      // this.computedNavSticky()
      window.addEventListener("scroll", throttle(this.pageScrollEvent, 200));
    },
    // 登录浮层接入
    async loginSuccess() {
      this.isLogin = true;
      await this.initFirstScreen();
      if (!this.questionareVisible) {
        this.initPage(false);
      }
    },
    async initFirstScreen() {
      try {
        await this.getFirstScreen({
          vueInstance: this,
          postData: this.$root.$$params,
        });
      if (!process.server) {
        document.getElementsByClassName("fixed-nav")[0].style.display = "none"
      }
        
      } catch (e) {
        console.log(e);
      }
    },
    // handleFinanceBannerClose () {
    //   this.keepHideFinanceBanner = true
    //   this.showFinanceBanner = false
    // }
  },
  async asyncData() {
    await this.initFirstScreen();
  },
  afterAsyncData() {
    let chn = this.$route.query.chn || ""
    let subChn = this.$route.query.subchn || ""
    this.$store.commit("updateState", { key: "chn", value: chn })
    this.$store.commit("updateState", { key: "subChn", value: subChn })
    if (!this.questionareVisible) {
      this.initPage(false);
    } else {
      window._xystj && window._xystj.trackEvent("Questionaire_show", "display");
    }
  }
}
</script>

import bg from '@/assets/img/claim/bg.png'
import icon1 from '@/assets/img/claim/icon1.png'
import icon2 from '@/assets/img/claim/icon2.png'
import icon3 from '@/assets/img/claim/icon3.png'
import icon4 from '@/assets/img/claim/icon4.png'
import icon5 from '@/assets/img/claim/icon5.png'
import icon6 from '@/assets/img/claim/icon6.png'
import icon7 from '@/assets/img/claim/icon7.png'
import icon8 from '@/assets/img/claim/icon8.png'

export const claimPictureMap = {
  bg,
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8
}
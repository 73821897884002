<template>
  <div class="search-wrap">
    <div class="wrap-content">
      <div class="search-bar">
        <i class="icon-left-arrow" @click.stop="cancel"></i>
        <div class="search-input">
          <i class="icon-sch"></i>
          <form class="search-form" action="javascript:;" >
            <input 
              type="text"
              :placeholder="hotPlaceholder"  
              v-model='searchValue'
              @keydown="keyDownEvent"
              autofocus="autofocus"
              maxlength="100"/>
          </form>
          <div class="input-right">
            <i class="icon-clear" v-show="searchValue"  @click.stop="clearInput"></i>
            <div class="search-btn" @click.stop="searchBtn">搜索</div>
          </div>
        </div>
      </div>
      <div class="search-scroll">
      <img class="topBg" src="https://sslstatic.xiaoyusan.com/img/index/topBg2.711be535fd613dfc.png" alt="">
        <div class="search-content">
            <!-- 热门搜索  -->
          <div class="hot-search" >
            <h3 class="search-title">热门搜索</h3>
            <div class="hot-word-wrap">
              <div v-for="(item,index) in hotList" :key="`hot_${index}`" 
                :class="['word-item',`${(index === 0 || index === 1) ? 'word-item-hot':''}`]"
                @click.stop="searchByHistoryValue(item)">
                <img src="https://sslstatic.xiaoyusan.com/img/index/fire.632384a8ac9a552b.png" class="hot_icon" v-if="index === 0 || index === 1">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="history-search" v-if="historyList.length">
            <div class="history-bar">
              <h3 class="search-title history-title">历史记录</h3>
              <div class="clear-icon" @click="clearHistory"></div>
            </div>
            <div class="history-word-wrap">
              <div v-for="(item,index) in historyList" :key="`history_${index}`" 
                class="word-item  row-1" 
                @click.stop="searchByHistoryValue(item)">
                {{ item }}
              </div>
              <div class="item-down icon-down" v-if="realHistoryList.length > 8" @click.stop="explandAll">
                <div :class="[`icon-down-inner`, `${arrowUp ? 'inner-rt':''}`]"></div>
              </div>
            </div>
          </div>
          <div class="search-content-bottom"></div>
        </div>

        <div class="popular-list" ref="topList" v-show="popularTabArr.length">
          <div class="list-wrap">
            <div class="list-header">
              <i class="icon-popular"></i>
              <span> 热门榜单 </span>
              <i class="icon-rightBg header-right"></i>
            </div>
            <div class="list-body">
              <div class="list-tab">
                <div class="tab-item" :class="{active: activeTab === item.tab}" v-for="(item, index) in popularTabArr" :key="`tab_${index}`" @click="changeTab(item)">
                  {{ item.name }}
                </div>
              </div>
              <div class="list-body-content">
                <a :href="item.src" class="list-body-item" v-for="(item, index) in activePopularList" :key="`listItem_${activeTab}_${index}`" :data-track="getTrackIndex(activeTab,index + 1)">
                  <div class="body-item-cover">
                    <i :class="['cover-no',`${(index === 0 || index === 1) ? coverNo[index]:'icon-no-other'}`]">{{index+1}}</i>
                    <img class="cover-img" :src="item.pic" alt="">
                  </div>
                  <div class="body-item-content">
                    <div class="content-title">{{item.title}}</div>
                    <div class="content-desc">
                      <div class="content-desc-left">
                        <span v-if="(item.desc && item.pvDesc)">
                          <span style="color: #333;">{{item.pvDesc}}</span>人关注
                        </span>
                        <span v-else>{{defaultDesc[activeTab]}}</span>
                      </div>
                      <div class="content-desc-right">
                        <span class="content-desc-text2">{{item.price}}</span>
                        <span class="content-desc-text3">{{item.saleTimeUnit ? `元起${item.saleTimeUnit}` : '元起'}}</span>
                        <i class="icon-right-arrow"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div :class="['more-question',`${serviceFix ? 'more-question-absolute':''}`]" @click.stop="contact"><i></i>更多问题，可以咨询在线客服</div>
      </div>
    </div>
  </div>
</template>
<script>
import { escapeHTML,unescapeHTML } from '@/assets/js/util'
export default {
  name: 'Search',
  data () {
    return {
      searchValue: '',
      hotPlaceholder: '',
      wordJump: [],
      realHistoryList: [],
      arrowUp: false,
      hotList: [],
      coverNo: ['icon-no1','icon-no2'],
      serviceFix: true,
      defaultDesc: {
        child: '少儿人气产品',
        finance: '财富人气产品',
        withinHundred: '百元人气产品',
      },
      activeTab: 'child',
      popularTabArr: []
    }
  },
    props: {
      searchWord: {
        type: Object,
        default:function(){
          return {}
        }
      },
      topGoods: {
        type: Object,
        default: () => {}
      },
      searchVisible: {
        type: Boolean,
        default:false
      }
  },
  computed:{
    historyList(){
      if(!this.arrowUp && this.realHistoryList.length && this.realHistoryList.length > 8){
        return this.realHistoryList.slice(0,8)
      } 
      return this.realHistoryList
    },

    activePopularList () {
      return this.topGoods[this.activeTab] || []
    }
  },
  watch: {
    searchWord: function(data){
      let defaultPlaceHolder = data.default || []
      if(defaultPlaceHolder.length){
        this.hotPlaceholder = defaultPlaceHolder[0] || ''
      }
      let hotSearchWord = data.hotSearchWord || []
      if(hotSearchWord.length){
        this.hotList = hotSearchWord.slice(0, 8)
      }
      this.realHistoryList = JSON.parse(localStorage.getItem('historyList') || '[]')
      this.wordJump = data.wordJump || []
      this.listenQuestion()
    },
    topGoods: function(){
        this.getPopularTabArr()
    },
    searchVisible: function(val){
      if(val === true){
        this.listenQuestion()
      }
    }
  },
  methods:{
    listenQuestion() {
      this.$nextTick(()=>{
        let rect = this.$refs.topList && this.$refs.topList.getBoundingClientRect()
        
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        if(rect && windowHeight - rect.bottom > 180){ 
          this.serviceFix = true
        }else{
          this.serviceFix = false
        }
      })
    },
    contact(){
      window.XYSConsultV3 && window.XYSConsultV3.genConsultUrl({ scene: 'search2020' }).then((url) => {
        if (url !== '') {
          location.href = url
        }
      })
    },
    cancel(){
      this.$emit('cancel')
    },
    clearHistory(){
      localStorage.setItem('historyList', JSON.stringify([]))
      this.realHistoryList = []
      this.listenQuestion()
    },
    clearInput(){
      this.searchValue = ''
    },
    searchEvent(word){
      let newList = [...new Set([word, ...this.realHistoryList])]
      if (newList.length > 20) {
        newList.pop()
      }
      localStorage.setItem('historyList', JSON.stringify(newList))
      //流量拦截
      let flag = this.wordJump.some(item => {
        if (String(item.word) === String(word)) {
          location.href = item.url
          return true
        }
      })
      if (flag) { return}
      let url = `${location.origin}/index/searchindex#/search?word=${encodeURIComponent(word)}&isIndex=1`
      location.href = url
    },
    keyDownEvent(e){
      if(Number(e.keyCode) === 13){
        this.searchBtn()
      }
    },
    searchBtn(){
        let word = this.searchValue.trim() || this.hotPlaceholder.trim()
        if(!word){
          this.$toast('请输入搜索内容',2000)
          return false
        }
        word = escapeHTML(word)
        this.searchEvent(word)
    },
    searchByHistoryValue(value){
      this.searchValue = unescapeHTML(value)
      this.searchEvent(value)
    },
    explandAll(){
      this.arrowUp = !this.arrowUp
      this.listenQuestion()
    },
    getPopularTabArr () {
      let arr = []
      const child = this.topGoods.child || []
      const withinHundred = this.topGoods.withinHundred || []
      const finance = this.topGoods.finance || []
      if(child.length) {
        arr.push({ tab: 'child', name: '儿童保险产品'})
      }
      if(finance.length) {
        arr.push({ tab: 'finance', name: '财富保险产品'})
      }
      if(withinHundred.length) {
        arr.push({ tab: 'withinHundred', name: '百元人气产品'})
      }
      if(arr && arr.length) {
        this.activeTab = arr[0].tab
      }
      this.popularTabArr = arr
    },
    changeTab (item) {
      this.activeTab = item.tab
    },
    getTrackIndex (activeTab, index) {
      let track = ''
      if (activeTab === 'child') {
        track = 'search_hot_'
      } else if(activeTab === 'withinHundred') {
        track = 'search_less100_'
      } else if (activeTab === 'finance') {
        track = 'search_fin_'
      }
      return index < 10 ? `${track}0${index}` : `${track}${index}`
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "concern" }, [
    _c("div", { staticClass: "concern-box" }, [
      _c(
        "a",
        {
          staticClass: "concern-text",
          attrs: { href: "javascript:;", "data-track": "detail_follow" }
        },
        [
          _vm._v(" 获取更多保险特卖福利，"),
          _c(
            "span",
            { staticClass: "concern-subscribe", on: { click: _vm.showQrCode } },
            [_vm._v("点击关注小雨伞公众号 >")]
          )
        ]
      ),
      _c("span", { staticClass: "concern-close", on: { click: _vm.close } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "company" }, [
    _c("div", { staticClass: "company-wrap" }, [
      _vm._m(0),
      _c("div", { staticClass: "company-list" }, [
        _c(
          "div",
          { staticClass: "company-wrap row" },
          [
            _c(
              "scroll",
              {
                ref: "scroll",
                staticClass: "nav-scroll",
                attrs: {
                  direction: "horizontal",
                  click: true,
                  listenScrollInit: true
                }
              },
              _vm._l(_vm.list, function(item, index) {
                return _c(
                  "div",
                  { key: "company" + index, staticClass: "company-item" },
                  [_c("img", { attrs: { src: item } })]
                )
              }),
              0
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "company-header" }, [
      _c("h2", { staticClass: "company-title" }, [_vm._v("合作保险公司")]),
      _c("div", { staticClass: "company-title-right" }, [
        _c(
          "a",
          {
            staticClass: "company_more",
            attrs: { href: "/aboutus/introduction" }
          },
          [_vm._v("更多")]
        ),
        _c("img", {
          staticClass: "icon-arrow",
          attrs: {
            src:
              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAATCAMAAACTKxybAAAAP1BMVEUAAADMzMy0tLS0tLS0tLS0tLS0tLS0tLS7u7u0tLS0tLSzs7O1tbW2tra5ubm/v7+zs7Ozs7O0tLS8vLyzs7OOcfb7AAAAFHRSTlMAB9G88LKNiBCpoJd8MhYM7MbFF8bQC5MAAABXSURBVAjXZY9ZEoAwCEMpFlu3unH/s2oNThnN3xsIJLRwTIFMrKrDS1FviVGvVR0ojKAVs+mhI/tNNkqgAprhI0gqbH4i3nO6a6X92fMnQcvmU//7+KYXr2oHoC6uNa8AAAAASUVORK5CYII=",
            alt: "了解更多"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", {
    staticClass: "back-top opacity-will-change",
    class: { transparent: !_vm.visible },
    style: "bottom:16rem",
    on: {
      click: function($event) {
        $event.stopPropagation()
        return _vm.toTop.apply(null, arguments)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
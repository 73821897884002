var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.indexRecIconNew.length
    ? _c(
        "section",
        { staticClass: "entries" },
        _vm._l(_vm.indexRecIconNew, function(item, index) {
          return _c(
            "a",
            {
              key: "entry_" + index,
              staticClass: "entries-item col",
              attrs: { href: item.url, "data-track": item.key }
            },
            [
              _vm.isSpecialActivity
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/img/special/" +
                        (index + 1) +
                        ".gif"),
                      alt: item.text
                    }
                  })
                : _c("img", { attrs: { src: item.icon, alt: item.text } }),
              _c("em", [_vm._v(" " + _vm._s(item.text) + " ")])
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "claim_wrap" }, [
    _c("div", { staticClass: "claim" }, [
      _c("img", {
        staticClass: "bg",
        attrs: { src: _vm.claimPictureMap.bg, alt: "明白买，放心赔" }
      }),
      _c("section", { staticClass: "claim_body" }, [
        _c("div", { staticClass: "claim_top" }, [
          _c("div", { staticClass: "claim_title" }, [
            _c("span", { staticClass: "sup_title" }, [
              _vm._v("明白买，放心赔")
            ]),
            _c("div", { staticClass: "claim_title_right" }, [
              _c("a", { staticClass: "claim_more", attrs: { href: _vm.url } }, [
                _vm._v("了解更多")
              ]),
              _c("img", {
                staticClass: "icon-arrow",
                attrs: {
                  src:
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAATCAMAAACTKxybAAAAP1BMVEUAAADMzMy0tLS0tLS0tLS0tLS0tLS0tLS7u7u0tLS0tLSzs7O1tbW2tra5ubm/v7+zs7Ozs7O0tLS8vLyzs7OOcfb7AAAAFHRSTlMAB9G88LKNiBCpoJd8MhYM7MbFF8bQC5MAAABXSURBVAjXZY9ZEoAwCEMpFlu3unH/s2oNThnN3xsIJLRwTIFMrKrDS1FviVGvVR0ojKAVs+mhI/tNNkqgAprhI0gqbH4i3nO6a6X92fMnQcvmU//7+KYXr2oHoC6uNa8AAAAASUVORK5CYII=",
                  alt: "了解更多"
                }
              })
            ])
          ]),
          _c("span", { staticClass: "sub_title" }, [
            _vm._v("小雨伞守护每个人的幸福")
          ])
        ]),
        _c("div", { staticClass: "claim_list" }, [
          _c("section", { staticClass: "row" }, [
            _c("div", { staticClass: "claim_item" }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.claimPictureMap.icon1, alt: "保障责任" }
              }),
              _c("section", { staticClass: "desc" }, [_vm._v("保障责任")])
            ]),
            _c("div", { staticClass: "claim_item" }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.claimPictureMap.icon2, alt: "价格明白" }
              }),
              _c("section", { staticClass: "desc" }, [_vm._v("价格明白")])
            ]),
            _c("div", { staticClass: "claim_item" }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.claimPictureMap.icon3, alt: "购买流程" }
              }),
              _c("section", { staticClass: "desc" }, [_vm._v("购买流程")])
            ]),
            _c("div", { staticClass: "claim_item" }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.claimPictureMap.icon4, alt: "理赔规则" }
              }),
              _c("section", { staticClass: "desc" }, [_vm._v("理赔规则")])
            ])
          ]),
          _c("section", { staticClass: "row" }, [
            _c("div", { staticClass: "claim_item" }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.claimPictureMap.icon5, alt: "全程协助" }
              }),
              _c("section", { staticClass: "desc" }, [_vm._v("全程协助")])
            ]),
            _c("div", { staticClass: "claim_item" }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.claimPictureMap.icon6, alt: "闪电赔付" }
              }),
              _c("section", { staticClass: "desc" }, [_vm._v("闪电赔付")])
            ]),
            _c("div", { staticClass: "claim_item" }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.claimPictureMap.icon7, alt: "法律援助" }
              }),
              _c("section", { staticClass: "desc" }, [_vm._v("法律援助")])
            ]),
            _c("div", { staticClass: "claim_item" }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: _vm.claimPictureMap.icon8, alt: "全天在线" }
              }),
              _c("section", { staticClass: "desc" }, [_vm._v("全天在线")])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
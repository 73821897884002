<template>
  <header ref="headerBar" :class="[isBigEvent ? 'headbar-gb-gray': isSpecialActivity ? 'headbar-gb-special' : 'headbar-gb', 'headbar ']" :style="`marginTop:${mt}rem;top:${mt}rem`" id="header-bar">
    <div :class="[isBigEvent ? 'logo-gray': 'logo']"></div>
    <div class="fun">
      <div class="func-item " @click.stop="search" data-track="search_new">
        <div class="search-input">
          <i class="icon-sch"></i>
            <div class="search-text">{{getDefaultWord(searchWord)}}</div>
        </div>
      </div>
      <a  :href="getUrl('/tips/user')" data-track="message_new" class="func-item">
        <i class="icon-message"></i>
      </a>
    </div>
  </header>
</template>
<script>
import { mapGetters, mapState } from "vuex"
import { changeChn } from "@/assets/js/util"
export default {
  name: 'HeaderBar',
  props: {
    mt: {
      type: Number,
      default: 0
    },
    showSearchBtn:{
      type:Boolean,
      default:false
    },
    unread:{
      type:Boolean,
      default:false
    },
    searchWord: {
      type: Object,
      default:function(){
        return {}
      }
    },
    isSpecialActivity: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['isBigEvent']),
    ...mapGetters(["chnMap"]),
  },
  methods:{
    search(){
      this.$emit('search')
    },
    getDefaultWord(data){
      let defaultPlaceHolder = data.default || []
      if(defaultPlaceHolder.length){
        let word =  defaultPlaceHolder[0] || '点击搜索'
        defaultPlaceHolder = word.length > 7 ? word.substr(0,7) + '...' : word
        return defaultPlaceHolder
      }
      return ''
    },
    getUrl (url) {
      return changeChn(url, this.chnMap)
    },
    getRect(){
      return this.$refs.headerBar.getBoundingClientRect()
    },
  }
  
}
</script>
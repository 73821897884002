<template>
  <div class="product-list">
    <template  v-for='(item,index) in list'>
      <div class="item-wrap product-item-wrap" :key="`product${index}`" v-if="Number(item.channelRecommend) === 1 || Number(classId) === 0">
        <a :href="item.src" 
          class="product-item" 
          :data-index="item.class"
          :data-id="item.productId"
          :data-classid="item.insuranceClassify.id"
          :data-track="`index_buy_new${getTrackIndex(index+1)}`">
          <div class="product-item-cover">
            <img :src="item.pic2" alt="" class="cover-img">
            <i class="product-item-shine new-yellow"  v-if="String(isNew) === '1' && String(item.recommendForNew) === '1'">新人优选</i>
            <i class="product-item-shine red" v-else-if="item.hot">热卖</i>
            <i class="product-item-shine green" v-else-if="item.isnew">新品</i>
            <img :src="item.customPic" alt="" v-else-if="item.customPic" class="label-pic">
          </div>
          <div class="product-item-content">
            <div class="detail">
              <h3 class="title product-title">
                <template v-if="item.diyTag">
                  <img src="@/assets/img/djlabel.png" alt="小雨伞保险" class="product_tag" v-if="Number(item.diyTag) === 1">
                  <img src="@/assets/img/dzlabel.png" alt="小雨伞保险" class="product_tag" v-if="Number(item.diyTag) === 2">
                </template>
                {{item.title}}
              </h3>
              <div class="product-desc">
                <section v-for="(descItem,descIndex) in item.advantage" :key="`desc${descIndex}`">{{descItem}}</section>
              </div>
              <div class="tag_wrap" v-if="item.tagList && item.tagList.length">
                <section v-for='(tagItem,tagIndex) in item.tagList' :key="`tag${tagIndex}`"  :class="['yellow', `${tagItem.class}`,'tag_item']">
                  {{tagItem.text}}
                </section>
              </div>
            </div>
            <div class="func product-func">
              <span class="price-value product-price-value">{{item.itemPrice}}</span>
              <div class="price-common">
                <p class="price-desc">
                  <span>{{item.priceUnit}}</span>
                  <span v-if="item.isBottomPrice" class="lowest-price-tip">详见费率表</span>
                </p>
                <div class="price-common-right">
                  <span class="comment" v-if="item.commentTip && Number(item.commentTip)>0">{{ item.commentTip }}条评价</span>
                  <i class="icon-arrow"></i>
                </div>
              </div>
              <!-- <div class="btn" :data-track="`buy_new${getTrackIndex(index+1)}`">
                {{item.saleTypeButtonText || '立即投保'}}
              </div> -->
            </div>
          </div>
        </a>
        <div class="recommend product-recommend" v-if="item.recommendInfo">
          <div class="recommend-img">
            <img :src="item.recommendInfo.picUrl" alt="">
          </div>
          <img class="recommend-rightBg" src="@/assets/img/recommend-rightBg.png" alt="">
          <div class="recommend-desc">{{item.recommendInfo.recommendWord}}</div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Product',
  props: {
    list: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    classId:{
      type:Number,
      default:0
    }
  },
  computed: {
    ...mapState(['isNew'])
  },
  methods:{
    getTrackIndex(index){
      return index < 10 ? `0${index}`:index
    }
  }
}
</script>
<template>
  <section class="claim_wrap">
    <div class="claim">
      <img :src="claimPictureMap.bg" alt="明白买，放心赔" class="bg" />
      <section class="claim_body">
        <div class="claim_top">
          <div class="claim_title">
            <span class="sup_title">明白买，放心赔</span>
            <div class="claim_title_right">
              <a :href="url" class="claim_more">了解更多</a>
              <img class="icon-arrow" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAATCAMAAACTKxybAAAAP1BMVEUAAADMzMy0tLS0tLS0tLS0tLS0tLS0tLS7u7u0tLS0tLSzs7O1tbW2tra5ubm/v7+zs7Ozs7O0tLS8vLyzs7OOcfb7AAAAFHRSTlMAB9G88LKNiBCpoJd8MhYM7MbFF8bQC5MAAABXSURBVAjXZY9ZEoAwCEMpFlu3unH/s2oNThnN3xsIJLRwTIFMrKrDS1FviVGvVR0ojKAVs+mhI/tNNkqgAprhI0gqbH4i3nO6a6X92fMnQcvmU//7+KYXr2oHoC6uNa8AAAAASUVORK5CYII=" alt="了解更多">
            </div>
          </div>
          <span class="sub_title">小雨伞守护每个人的幸福</span>
          
        </div>
        <div class="claim_list">
          <section class="row">
            <div class="claim_item">
              <img :src="claimPictureMap.icon1" alt="保障责任" class="icon" />
              <section class="desc">保障责任</section>
            </div>
            <div class="claim_item">
              <img :src="claimPictureMap.icon2" alt="价格明白" class="icon" />
              <section class="desc">价格明白</section>
            </div>
            <div class="claim_item">
              <img :src="claimPictureMap.icon3" alt="购买流程" class="icon" />
              <section class="desc">购买流程</section>
            </div>
            <div class="claim_item">
              <img :src="claimPictureMap.icon4"  alt="理赔规则" class="icon" />
              <section class="desc">理赔规则</section>
            </div>
          </section>
          <section class="row">
            <div class="claim_item">
              <img :src="claimPictureMap.icon5" alt="全程协助" class="icon" />
              <section class="desc">全程协助</section>
            </div>
            <div class="claim_item">
              <img :src="claimPictureMap.icon6" alt="闪电赔付" class="icon" />
              <section class="desc">闪电赔付</section>
            </div>
            <div class="claim_item">
              <img :src="claimPictureMap.icon7" alt="法律援助" class="icon" />
              <section class="desc">法律援助</section>
            </div>
            <div class="claim_item">
              <img :src="claimPictureMap.icon8" alt="全天在线" class="icon" />
              <section class="desc">全天在线</section>
            </div>
          </section>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { claimPictureMap } from '@/assets/js/config/claim.js'
export default {
  name: 'Claim',
  props:{
    url:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      claimPictureMap:claimPictureMap
    }
  }
}
</script>
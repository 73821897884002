var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      ref: "headerBar",
      class: [
        _vm.isBigEvent
          ? "headbar-gb-gray"
          : _vm.isSpecialActivity
          ? "headbar-gb-special"
          : "headbar-gb",
        "headbar "
      ],
      style: "marginTop:" + _vm.mt + "rem;top:" + _vm.mt + "rem",
      attrs: { id: "header-bar" }
    },
    [
      _c("div", { class: [_vm.isBigEvent ? "logo-gray" : "logo"] }),
      _c("div", { staticClass: "fun" }, [
        _c(
          "div",
          {
            staticClass: "func-item ",
            attrs: { "data-track": "search_new" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.search.apply(null, arguments)
              }
            }
          },
          [
            _c("div", { staticClass: "search-input" }, [
              _c("i", { staticClass: "icon-sch" }),
              _c("div", { staticClass: "search-text" }, [
                _vm._v(_vm._s(_vm.getDefaultWord(_vm.searchWord)))
              ])
            ])
          ]
        ),
        _c(
          "a",
          {
            staticClass: "func-item",
            attrs: {
              href: _vm.getUrl("/tips/user"),
              "data-track": "message_new"
            }
          },
          [_c("i", { staticClass: "icon-message" })]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="entries" v-if="indexRecIconNew.length">
    <a v-for="(item,index) in indexRecIconNew" :key="`entry_${index}`" :href="item.url" :data-track='item.key' class="entries-item col">
      <img :src="require(`@/assets/img/special/${index+1}.gif`)" :alt="item.text" v-if="isSpecialActivity">
      <img :src="item.icon" :alt="item.text" v-else>
      <em> {{item.text}} </em>
    </a>
  </section>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Entry',
  props:{
    isSpecialActivity: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    ...mapState(['indexRecIconNew'])
  }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "finance-wrapper" },
    [
      _vm._l(_vm.list, function(financeItem, financeIndex) {
        return [
          _c(
            "div",
            { key: "finance" + financeIndex, staticClass: "finance-list" },
            [
              financeItem.productList.length
                ? [
                    _c("div", { staticClass: "finance-nav" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(financeItem.text))
                      ]),
                      _c("div", { staticClass: "desc" }, [
                        _vm._v(_vm._s(financeItem.desc))
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "finance-product-list" },
                      [
                        _vm._l(financeItem.productList, function(item, index) {
                          return [
                            Number(item.channelRecommend) === 1
                              ? _c(
                                  "div",
                                  {
                                    key: "product-" + index,
                                    staticClass: "item-wrap finance-item-wrap"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "product-item",
                                        attrs: {
                                          href: item.src,
                                          "data-id": item.productId,
                                          "data-classid":
                                            item.insuranceClassify.id,
                                          "data-track":
                                            "buy_new2020" +
                                            _vm.getTrackIndex(index + 1)
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "product-item-cover" },
                                          [
                                            _c("img", {
                                              staticClass: "cover-img",
                                              attrs: { src: item.pic2, alt: "" }
                                            }),
                                            String(_vm.isNew) === "1" &&
                                            String(item.recommendForNew) === "1"
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "finance-item-shine new-yellow"
                                                  },
                                                  [_vm._v("新人优选")]
                                                )
                                              : item.hot
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "finance-item-shine red"
                                                  },
                                                  [_vm._v("热卖")]
                                                )
                                              : item.isnew
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "finance-item-shine green"
                                                  },
                                                  [_vm._v("新品")]
                                                )
                                              : item.customPic
                                              ? _c("img", {
                                                  staticClass: "label-pic",
                                                  attrs: {
                                                    src: item.customPic,
                                                    alt: ""
                                                  }
                                                })
                                              : _vm._e(),
                                            item.videourl
                                              ? _c("i", {
                                                  staticClass: "product-video"
                                                })
                                              : _vm._e()
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "product-item-content"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "detail" },
                                              [
                                                _c(
                                                  "h3",
                                                  {
                                                    staticClass:
                                                      "title finance-title"
                                                  },
                                                  [
                                                    item.diyTag
                                                      ? [
                                                          Number(
                                                            item.diyTag
                                                          ) === 1
                                                            ? _c("img", {
                                                                staticClass:
                                                                  "product_tag",
                                                                attrs: {
                                                                  src: require("@/assets/img/djlabel.png"),
                                                                  alt:
                                                                    "小雨伞保险"
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          Number(
                                                            item.diyTag
                                                          ) === 2
                                                            ? _c("img", {
                                                                staticClass:
                                                                  "product_tag",
                                                                attrs: {
                                                                  src: require("@/assets/img/dzlabel.png"),
                                                                  alt:
                                                                    "小雨伞保险"
                                                                }
                                                              })
                                                            : _vm._e()
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.title) +
                                                        " "
                                                    )
                                                  ],
                                                  2
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "finance-desc"
                                                  },
                                                  _vm._l(
                                                    item.advantage,
                                                    function(
                                                      descItem,
                                                      descIndex
                                                    ) {
                                                      return _c(
                                                        "section",
                                                        {
                                                          key:
                                                            "desc" + descIndex
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(descItem)
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                item.tagList &&
                                                item.tagList.length
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "tag_wrap finance_tag_wrap"
                                                      },
                                                      _vm._l(
                                                        item.tagList,
                                                        function(
                                                          tagItem,
                                                          tagIndex
                                                        ) {
                                                          return _c(
                                                            "section",
                                                            {
                                                              key:
                                                                "tag" +
                                                                tagIndex,
                                                              staticClass:
                                                                "tag_item yellow"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    tagItem.text
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "func finance-func"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "price-value finance-price-value"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.itemPrice)
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "price-common"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "price-desc"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.priceUnit
                                                            )
                                                          )
                                                        ]),
                                                        item.isBottomPrice
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "lowest-price-tip"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "详见费率表"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      item.commentTip &&
                                                      Number(item.commentTip) >
                                                        100
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "comment"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.commentTip
                                                                ) + "条评价"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-arrow"
                                                      })
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    item.recommendInfo
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "recommend finance-recommend"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "recommend-img" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      item.recommendInfo.picUrl,
                                                    alt: ""
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "recommend-desc" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.recommendInfo
                                                      .recommendWord
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("img", {
                                              staticClass: "recommend-rightBg",
                                              attrs: {
                                                src: require("@/assets/img/recommend-rightBg.png"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div 
    :class="[...navClass,'nav-wrap']"
    :style="`top:${navTop+downloadHeight}rem;`"
    ref="nav"
  >
    <!-- 
      :style="`fontWeight: ${(getAnimationVisible(item) || isSelected(index)) ? 700 : 400}; fontSize: ${(getAnimationVisible(item) || isSelected(index)) ? 1.6 : 1.4}rem;`"
     -->
    <a
      v-for="(item, index) in list"
      class="nav-item"
      :key="`nav${index}`"
      :class="{ active: isSelected(index), finance: isFinance(item) }"
      :data-track="item.track"
      @click.stop="changeTab(index)"
    > 
      <span class="text ani-before" v-show="getAnimationVisible(item) && playStatus === 1">
        {{ item.text }}
      </span>
      <span class="nav-icon" :id="`nav-${item.class}`" :ref="`nav-${item.class}`" v-show="getAnimationVisible(item) && playStatus === 2"></span>
      <span class="text ani-after" v-show="getAnimationVisible(item) && playStatus === 3">
        {{ item.text }}
      </span>
      <!-- 以上是动画 -->
      <span class="text" :class="{ active: isSelected(index), 'rela': isTaxAdv(item) }" :style="`opacity: ${getAnimationVisible(item) && playStatus  ? 0 : 1};`">
        {{ item.text }}
        <i v-if="isTaxAdv(item)" class="icon-shuiyou"></i>
      </span>
    </a>
  </div>
</template>
<script>
import lottie from 'lottie-web'
export default {
  name: 'ProductNav',
  props: {
    list: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    active: {
      type: Number,
      default: 0
    },
    border:{
      type: Boolean,
      default: false
    },
    navTop: {
      type: Number,
      default: 0
    },
    downloadHeight: {
      type: Number,
      default: 0
    },
  },
  data(){
    return {
      listElements: null,
      scroll: null,
      financeClassId: 10,
      taxAdvClassId: 14,
      animation: null,
      animationVisible: false,
      playStatus: 0,
      playInterval: null
    }
  },
  computed: {
    len () {
      return this.list.length || 0
    },
    between3To5 () {
      // return this.len && this.len > 2 && this.len <= 5
      return true
    },
    navClass () {
      return [`stick`,`${this.between3To5 ? 'nav-wrap-below':'nav-wrap-over'}`]
    },
    isSelectedFinance () {
      let isSelectedFinance = false
      this.list.forEach((e, index) => {
        if (Number(e.class) === Number(this.financeClassId)) {
          isSelectedFinance = this.isSelected(index)
        }
      })
      return isSelectedFinance
    }
  },
  mounted () {
    let endTimeStamp = 1680278400000 // (new Date('2023-04-01 00:00:00')).getTime()
    let currentTime = (new Date()).getTime()
    if (currentTime < endTimeStamp) {
      this.animationVisible = true
      this.initLottieAnimation()
      this.animationPlay()
      this.playInterval = setInterval(()=>{
        this.animationPlay()
      }, 9200)
    }
  },
  methods: {
    animationPlay () {
      this.playStatus = 1
      let timer1 = setTimeout(() => {
        this.playStatus = 2
        this.animation.play()
        clearTimeout(timer1)
        timer1 = null
        let timer2 = setTimeout(() => {
          this.playStatus = 3
          this.animation.stop()
          clearTimeout(timer2)
          timer2 = null
        }, 4000)
      }, 100)
    },
    isFinance (item) {
      return Number(item.class) === Number(this.financeClassId)
    },
    isTaxAdv (item) {
      return Number(item.class) === Number(this.taxAdvClassId)
    },
    isSelected (index) {
      return this.active === index
    },
    getAnimationVisible (item) {
      let isFinance = this.isFinance(item)
      // 财富频道且选中的不是财富频道
      return isFinance && !this.isSelectedFinance && this.animationVisible
    },
    changeTab (index) {
      this.$emit('change', index)
    },
    refreshScroll(){
      if (!this.between3To5) {
        this.$refs.scroll.initScroll()
      }
    },
    getRect(){
      return this.$refs.nav.getBoundingClientRect()
    },
    scrollSuc (scroll) {
      this.scroll = scroll
    },
    scrollTo (index) {
      let i = 0
      if (Number(index) <= 2) {
        i = 0
      } else if (Number(index) >= Number(this.len) - 2) {
        i = this.len - 4
      } else {
        i = Number(index) - 2
      }
      if (this.scroll) {
        this.scroll.scrollToElement(this.listElements[i], 100)
      }
    },
    initLottieAnimation () {
      lottie.setIDPrefix('zaofujie')
      this.animation = lottie.loadAnimation({
        container: this.$refs[`nav-${this.financeClassId}`][0],
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: 'https://sslstatic.xiaoyusan.com/js/home/zaofujie.c9c22803c7fa298c.json'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .scroll-wrapper {
  height: 4.2rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
/deep/ .scroll-content {
  width: unset;
  height: 4.2rem;
  display: inline-block;
  position: relative;
}
.icon-shuiyou {
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  width: 2.9rem;
  height: 2.3rem;
  background: url('https://sslstatic.xiaoyusan.com/img/icon/icon-shuiyou.65cd988982ee892f.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}
.rela {
  position: relative;
}
</style>
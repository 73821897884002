var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "activity_btn", attrs: { href: _vm.data.url } },
    [_c("img", { attrs: { src: _vm.data.src, alt: "" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="contact row">
    <a :href="url" class="btn-item row" data-track="publicInfo">
      <i class="icon icon-info"></i>
      <span>信息披露</span>
    </a>
    <a href="javascript:;" class="btn-item row" data-track="index_webCustomerSevice_new" @click="contact">
      <i class="icon icon-service"></i>
      <span>在线客服</span>
    </a>
    <a href="tel:400-919-7788" class="btn-item row" data-track="index_phone">
      <i class="icon icon-tel"></i>
      <span>电话:</span>
      <em>400-919-7788</em>
    </a>
    </section>
</template>
<script>
import { mapGetters } from "vuex"
import { changeChn } from "@/assets/js/util"
export default {
  name: 'Contact',
  props:{
    url:{
      type:String,
      default:''
    }
  },
  computed: {
    ...mapGetters(["chnMap"]),
  },
  methods:{
    contact(){
      window.XYSConsultV3.genConsultUrl({ scene: 'h5index' }).then((url)=> {
        if (url !== '') {
          location.href = changeChn(url, this.chnMap)
        }
      })
    }
  }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "nav",
      class: _vm.navClass.concat(["nav-wrap"]),
      style: "top:" + (_vm.navTop + _vm.downloadHeight) + "rem;"
    },
    _vm._l(_vm.list, function(item, index) {
      return _c(
        "a",
        {
          key: "nav" + index,
          staticClass: "nav-item",
          class: {
            active: _vm.isSelected(index),
            finance: _vm.isFinance(item)
          },
          attrs: { "data-track": item.track },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.changeTab(index)
            }
          }
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getAnimationVisible(item) && _vm.playStatus === 1,
                  expression: "getAnimationVisible(item) && playStatus === 1"
                }
              ],
              staticClass: "text ani-before"
            },
            [_vm._v(" " + _vm._s(item.text) + " ")]
          ),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.getAnimationVisible(item) && _vm.playStatus === 2,
                expression: "getAnimationVisible(item) && playStatus === 2"
              }
            ],
            ref: "nav-" + item.class,
            refInFor: true,
            staticClass: "nav-icon",
            attrs: { id: "nav-" + item.class }
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getAnimationVisible(item) && _vm.playStatus === 3,
                  expression: "getAnimationVisible(item) && playStatus === 3"
                }
              ],
              staticClass: "text ani-after"
            },
            [_vm._v(" " + _vm._s(item.text) + " ")]
          ),
          _c(
            "span",
            {
              staticClass: "text",
              class: {
                active: _vm.isSelected(index),
                rela: _vm.isTaxAdv(item)
              },
              style:
                "opacity: " +
                (_vm.getAnimationVisible(item) && _vm.playStatus ? 0 : 1) +
                ";"
            },
            [
              _vm._v(" " + _vm._s(item.text) + " "),
              _vm.isTaxAdv(item)
                ? _c("i", { staticClass: "icon-shuiyou" })
                : _vm._e()
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="banner">
    <div class="banner-list" data-track="index_rotation_new01">
      <a href="javascript:;" class="banner-item" v-if="ssr">
        <img :src="firstScreen" alt="" class="banner-img" />
      </a>
      <template v-else>
        <swiper ref="bannerSwiper" class="my-swiper" :options="swiperOption">
          <swiper-slide v-for="(item, index) of list" :key="`banner_${index}`">
            <a
              href="javascript:;"
              class="banner-item"
              :data-track="`index_rotation_new${getTrackIndex(index + 1)}`"
              @click.stop="linkTo(item)"
            >
              <img :src="item.pic" alt="" class="banner-img" />
            </a>
          </swiper-slide>
        </swiper>
        <div class="idots_list" v-if="list.length">
          <i
            v-for="(item, index) in list.length"
            :class="[index === curIndex ? 'active' : '', 'idots']"
            :key="`idots-${index}`"
            @click.stop="changeBanner(index)"
          ></i>
        </div>
      </template>
    </div>
    <div class="banner-placeholder"></div>
  </div>
</template>
<script>
import { mapState , mapGetters} from "vuex";
import { changeChn } from "@/assets/js/util";
import { canOpenMiniProgram, openMiniProgram } from "@/assets/js/weixin";
export default {
  name: "Banner",
  props: {
    ssr: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      curIndex: 0,
      swiperOption: {
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        speed: 400,
        loop: false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        setWrapperSize: true,
        on: {
          transitionEnd: () => {
            // old version
            this.curIndex = this.$refs.bannerSwiper.swiper&&this.$refs.bannerSwiper.swiper.realIndex
          },
        },
        onTransitionEnd: () => {
          // new version
          this.curIndex = this.$refs.bannerSwiper.swiper&&this.$refs.bannerSwiper.swiper.realIndex
        }
      }
    }
  },
  computed: {
    ...mapState(["indexBanner"]),
    ...mapGetters(["chnMap"]),
    firstScreen() {
      return this.indexBanner.length && this.indexBanner[0].pic
    },
    list() {
      let list = this.indexBanner.slice(0, 5)
      for (let item of list) {
        // 跳转类型, 1普通链接, 2小程序链接（app端才会有这种情况）
        if (Number(item.jumpType) !== 2) {
          item.url = changeChn(item.url, this.chnMap)
        }
      }
      return list;
    }
  },
  methods: {
    getTrackIndex(index) {
      return index < 10 ? `0${index}` : index;
    },
    changeBanner(index) {
      this.curIndex = index;
      this.$refs.bannerSwiper.swiper.slideTo(index, 300);
    },
    linkTo(item) {
      if (Number(item.jumpType) !== 2) {
        location.href = item.url
      } else {
        try {
          canOpenMiniProgram((res) => {
            if (res) {
              openMiniProgram(item.originId, item.pagePath, item.url);
            } else {
              console.log("不支持跳转小程序失败");
            }
          });
        } catch (e) {
          console.log("跳转小程序失败", e);
        }
      }
    }
  }
}
</script>

<template>
  <section class="company">
    <div class="company-wrap">
      <div class="company-header">
        <h2 class="company-title">合作保险公司</h2>
        <div class="company-title-right">
          <a class="company_more" href="/aboutus/introduction">更多</a>
          <img class="icon-arrow" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAATCAMAAACTKxybAAAAP1BMVEUAAADMzMy0tLS0tLS0tLS0tLS0tLS0tLS7u7u0tLS0tLSzs7O1tbW2tra5ubm/v7+zs7Ozs7O0tLS8vLyzs7OOcfb7AAAAFHRSTlMAB9G88LKNiBCpoJd8MhYM7MbFF8bQC5MAAABXSURBVAjXZY9ZEoAwCEMpFlu3unH/s2oNThnN3xsIJLRwTIFMrKrDS1FviVGvVR0ojKAVs+mhI/tNNkqgAprhI0gqbH4i3nO6a6X92fMnQcvmU//7+KYXr2oHoC6uNa8AAAAASUVORK5CYII=" alt="了解更多">
        </div>
      </div>
      <div class="company-list">
        <div class="company-wrap row">
          <scroll
            class="nav-scroll"
            direction="horizontal"
            ref="scroll"
            :click="true"
            :listenScrollInit="true"
          > 
            <div class="company-item" v-for="(item, index) in list" :key="`company${index}`">
              <img :src="item">
            </div>
          </scroll>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import scroll from '@/views/common/scroll.vue'
import { companyList } from '@/assets/js/config/company.js'
export default {
  name: 'Company',
  components: {
    scroll
  },
  data(){
    return {
      list: companyList
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .scroll-wrapper {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
/deep/ .scroll-content {
  width: unset;
  display: inline-block;
  position: relative;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "banner" }, [
    _c(
      "div",
      {
        staticClass: "banner-list",
        attrs: { "data-track": "index_rotation_new01" }
      },
      [
        _vm.ssr
          ? _c(
              "a",
              { staticClass: "banner-item", attrs: { href: "javascript:;" } },
              [
                _c("img", {
                  staticClass: "banner-img",
                  attrs: { src: _vm.firstScreen, alt: "" }
                })
              ]
            )
          : [
              _c(
                "swiper",
                {
                  ref: "bannerSwiper",
                  staticClass: "my-swiper",
                  attrs: { options: _vm.swiperOption }
                },
                _vm._l(_vm.list, function(item, index) {
                  return _c("swiper-slide", { key: "banner_" + index }, [
                    _c(
                      "a",
                      {
                        staticClass: "banner-item",
                        attrs: {
                          href: "javascript:;",
                          "data-track":
                            "index_rotation_new" + _vm.getTrackIndex(index + 1)
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.linkTo(item)
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "banner-img",
                          attrs: { src: item.pic, alt: "" }
                        })
                      ]
                    )
                  ])
                }),
                1
              ),
              _vm.list.length
                ? _c(
                    "div",
                    { staticClass: "idots_list" },
                    _vm._l(_vm.list.length, function(item, index) {
                      return _c("i", {
                        key: "idots-" + index,
                        class: [
                          index === _vm.curIndex ? "active" : "",
                          "idots"
                        ],
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeBanner(index)
                          }
                        }
                      })
                    }),
                    0
                  )
                : _vm._e()
            ]
      ],
      2
    ),
    _c("div", { staticClass: "banner-placeholder" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-wrap" }, [
    _c("div", { staticClass: "wrap-content" }, [
      _c("div", { staticClass: "search-bar" }, [
        _c("i", {
          staticClass: "icon-left-arrow",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.cancel.apply(null, arguments)
            }
          }
        }),
        _c("div", { staticClass: "search-input" }, [
          _c("i", { staticClass: "icon-sch" }),
          _c(
            "form",
            { staticClass: "search-form", attrs: { action: "javascript:;" } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchValue,
                    expression: "searchValue"
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.hotPlaceholder,
                  autofocus: "autofocus",
                  maxlength: "100"
                },
                domProps: { value: _vm.searchValue },
                on: {
                  keydown: _vm.keyDownEvent,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchValue = $event.target.value
                  }
                }
              })
            ]
          ),
          _c("div", { staticClass: "input-right" }, [
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchValue,
                  expression: "searchValue"
                }
              ],
              staticClass: "icon-clear",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.clearInput.apply(null, arguments)
                }
              }
            }),
            _c(
              "div",
              {
                staticClass: "search-btn",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.searchBtn.apply(null, arguments)
                  }
                }
              },
              [_vm._v("搜索")]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "search-scroll" }, [
        _c("img", {
          staticClass: "topBg",
          attrs: {
            src:
              "https://sslstatic.xiaoyusan.com/img/index/topBg2.711be535fd613dfc.png",
            alt: ""
          }
        }),
        _c("div", { staticClass: "search-content" }, [
          _c("div", { staticClass: "hot-search" }, [
            _c("h3", { staticClass: "search-title" }, [_vm._v("热门搜索")]),
            _c(
              "div",
              { staticClass: "hot-word-wrap" },
              _vm._l(_vm.hotList, function(item, index) {
                return _c(
                  "div",
                  {
                    key: "hot_" + index,
                    class: [
                      "word-item",
                      "" + (index === 0 || index === 1 ? "word-item-hot" : "")
                    ],
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.searchByHistoryValue(item)
                      }
                    }
                  },
                  [
                    index === 0 || index === 1
                      ? _c("img", {
                          staticClass: "hot_icon",
                          attrs: {
                            src:
                              "https://sslstatic.xiaoyusan.com/img/index/fire.632384a8ac9a552b.png"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(item) + " ")
                  ]
                )
              }),
              0
            )
          ]),
          _vm.historyList.length
            ? _c("div", { staticClass: "history-search" }, [
                _c("div", { staticClass: "history-bar" }, [
                  _c("h3", { staticClass: "search-title history-title" }, [
                    _vm._v("历史记录")
                  ]),
                  _c("div", {
                    staticClass: "clear-icon",
                    on: { click: _vm.clearHistory }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "history-word-wrap" },
                  [
                    _vm._l(_vm.historyList, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: "history_" + index,
                          staticClass: "word-item  row-1",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.searchByHistoryValue(item)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item) + " ")]
                      )
                    }),
                    _vm.realHistoryList.length > 8
                      ? _c(
                          "div",
                          {
                            staticClass: "item-down icon-down",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.explandAll.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("div", {
                              class: [
                                "icon-down-inner",
                                "" + (_vm.arrowUp ? "inner-rt" : "")
                              ]
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "search-content-bottom" })
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.popularTabArr.length,
                expression: "popularTabArr.length"
              }
            ],
            ref: "topList",
            staticClass: "popular-list"
          },
          [
            _c("div", { staticClass: "list-wrap" }, [
              _vm._m(0),
              _c("div", { staticClass: "list-body" }, [
                _c(
                  "div",
                  { staticClass: "list-tab" },
                  _vm._l(_vm.popularTabArr, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: "tab_" + index,
                        staticClass: "tab-item",
                        class: { active: _vm.activeTab === item.tab },
                        on: {
                          click: function($event) {
                            return _vm.changeTab(item)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "list-body-content" },
                  _vm._l(_vm.activePopularList, function(item, index) {
                    return _c(
                      "a",
                      {
                        key: "listItem_" + _vm.activeTab + "_" + index,
                        staticClass: "list-body-item",
                        attrs: {
                          href: item.src,
                          "data-track": _vm.getTrackIndex(
                            _vm.activeTab,
                            index + 1
                          )
                        }
                      },
                      [
                        _c("div", { staticClass: "body-item-cover" }, [
                          _c(
                            "i",
                            {
                              class: [
                                "cover-no",
                                "" +
                                  (index === 0 || index === 1
                                    ? _vm.coverNo[index]
                                    : "icon-no-other")
                              ]
                            },
                            [_vm._v(_vm._s(index + 1))]
                          ),
                          _c("img", {
                            staticClass: "cover-img",
                            attrs: { src: item.pic, alt: "" }
                          })
                        ]),
                        _c("div", { staticClass: "body-item-content" }, [
                          _c("div", { staticClass: "content-title" }, [
                            _vm._v(_vm._s(item.title))
                          ]),
                          _c("div", { staticClass: "content-desc" }, [
                            _c("div", { staticClass: "content-desc-left" }, [
                              item.desc && item.pvDesc
                                ? _c("span", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#333" } },
                                      [_vm._v(_vm._s(item.pvDesc))]
                                    ),
                                    _vm._v("人关注 ")
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.defaultDesc[_vm.activeTab])
                                    )
                                  ])
                            ]),
                            _c("div", { staticClass: "content-desc-right" }, [
                              _c(
                                "span",
                                { staticClass: "content-desc-text2" },
                                [_vm._v(_vm._s(item.price))]
                              ),
                              _c(
                                "span",
                                { staticClass: "content-desc-text3" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.saleTimeUnit
                                        ? "元起" + item.saleTimeUnit
                                        : "元起"
                                    )
                                  )
                                ]
                              ),
                              _c("i", { staticClass: "icon-right-arrow" })
                            ])
                          ])
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ]
        ),
        _c(
          "div",
          {
            class: [
              "more-question",
              "" + (_vm.serviceFix ? "more-question-absolute" : "")
            ],
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.contact.apply(null, arguments)
              }
            }
          },
          [_c("i"), _vm._v("更多问题，可以咨询在线客服")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-header" }, [
      _c("i", { staticClass: "icon-popular" }),
      _c("span", [_vm._v(" 热门榜单 ")]),
      _c("i", { staticClass: "icon-rightBg header-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="questionare_container">
    <div class="questionare ques-bg">
      <div class="top">
        <p class="just-look" data-track="Questionaire_stroll" @click.stop="justLook">随便逛逛</p>
        <div class="progress" v-if="totalStep">
          <section class="step" :style="`width:${stepWidth}rem`"></section>
        </div>
        <p class="just-look" v-if="firstStep"></p>
        <p class="just-look" v-else @click.stop="prev">上一步</p>
      </div>
      <p class="title" v-for="(item,index) in realTitle" :key="`ques${index}`">{{item}}</p>
      <p class="desc">
        填写信息，获取<span>智能推荐</span>方案
      </p>
      <div class="trans-wrap">
        <div class="ques-list" v-if="firstStep">
          <div class="ques-item" v-for="(item,index) in firstAnswerList" :key="`ques${index}`" @click="selectQuestion(item.value,index)">
            <p class="title">{{item.label}}</p>
            <div class="select-icon"></div>
            <div class="tag" v-if="item.tips">{{item.tips}}</div>
            
          </div>
        </div>
        <div class="insurance" v-else>
          <div class="insurance-bg">
            <div class="ques-title">您可以选择{{realMultiSelect ? '多':'单'}}个保险类型哦</div>
            <!-- <img :src="require(`@/assets/img/${selectedValue}.png`)" alt=""  class="cover-pic"/> -->
          </div>
          <div class="insurance-list">
            <div class="row" v-for="(lineItem,lineIndex) in chunkAnswers" :key="`line${lineIndex}`">
              <div  v-for="(item,index) in lineItem" :key="`an${index}`"  @click="selectAnswer(lineIndex,index,item.active)"
                class="insurance-item" :class="{ active: item.active || false }">
                {{ item.label}}
                <div class="tip" v-if="item.tips">{{item.tips}}</div>
                <div class="select" >
                  <img src="@/assets/img/ok.png" alt=""  class="select-icon" v-if="item.active"/>
                </div>
              </div>
            </div>
          </div>
        </div>  
        <template v-for="(item,index) in firstAnswerList">
          <img @click="selectQuestion(item.value,index)"
            :key="`pic${index}`" 
            :src="require(`@/assets/img/${item.value}.png`)"
            :alt="item.label" 
            :style="`top:${getTopValue(index)}rem;opacity:${getOpacity(item.value)};`"
            :class="[ firstStep ? 'before-animation':'after-animation','animation-pic', getTransition(item.value,index)]" />
        </template> 
      </div>
      <div class="btn"  
        v-if="nextVisible"
        @click.stop="next">下一步</div>
      <div class="btn"  
        v-if="lastStep"
        data-track="Questionaire_submit"
        @click.stop="confirm">提交完成推荐</div>
      <p class="tips">*您所填写的信息都将严格保密</p>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Questionare',
  data(){
    return {
      currentStep:0,
      selectedValue:'',
      trans:['transition-has-first','transition-has','transition-has']
    }
  },
  methods:{
    getTopValue(index){
      if(this.firstStep){
        const topValue = 14.25 * index + 1.9
        return topValue
      }
      return 0.8
    },
    getOpacity(value){
      if(this.firstStep){
        return 1
      }
      if(value === this.selectedValue){
        return 1
      }
      return 0
    },
    getTransition(value,index){
      if(this.firstStep && !this.selectedValue){
        return this.trans[index]
      }
      if(value === this.selectedValue){
        return this.trans[index]
      }
      return ''
    },
    selectQuestion(value,index){
      this.currentStep++
      this.selectedValue = value
      for(let item of this.firstAnswerList){
        item.active = false
      }
      this.firstAnswerList[index].active = true
    },
    selectAnswer(lineIndex,index,selectActive){
      let i = lineIndex * 2 + index
      if(this.realMultiSelect){
        this.realAnswers[i].active = !selectActive
      } else {
        if(selectActive){
          return false
        }
        this.realAnswers.forEach(item=>{
          item.active = false
        })
        this.realAnswers[i].active = true
      }
      this.$forceUpdate()
    },
    getSelectedValues(){
      let list = []
      for(let item of this.questionareList){
        let answers = []
        for(let aItem of item.answers ){
          if(aItem.active){
            answers.push(aItem.value)
          }
        }
        
        list.push({
          title: item.title.join(''),
          answers:answers
        })
      }
      return list
    },
    fillQuestionare(postData){
      this.$$fetch({ 
        url: '/index/fillQuestionare',
        data:postData
      }).then(res => {
        const { ret, msg} = res || {}
        if(Number(ret) === 0){
          this.linkTo(false)
        } else if(Number(ret) === 10001){ // 未登录
          this.$toast(msg, 2000)
          this.linkTo(true)
        } else{
          this.$toast(msg, 2000)
        }
      }).catch(e => {
        console.log(e)
      })
    },
    justLook(){
      this.fillQuestionare({
        justBrowse:true
      })
    },
    confirm(){
      const params = this.getSelectedValues()
      if(!params.length){
        this.$toast('你还未完整填写信息哦~',2000)
        return 
      }
      let hasEmpty = false
      for(let item of params){
        if(!item.answers || !item.answers.length){
          hasEmpty = true 
          break
        }
      }
      if(hasEmpty){
        this.$toast('你还未完整填写信息哦~',2000)
        return 
      }
      this.fillQuestionare({
        quest:params
      })
    },
    linkTo(isTrue = false){
      this.$store.commit('updateState',[
        {key: 'questionareVisible', value: false}
      ])
      this.$emit('fill',isTrue)
    },
    chunk (arr, size) {
      const list = []
      for (let i = 0; i < arr.length; i = i + size) {
        list.push(arr.slice(i, i + size))
      }
      return list
    },
    prev(){
      for(let item of this.realAnswers){
        item.active = false
      }
      this.currentStep = Number(this.currentStep) - 1
    },
    next(){
      let valid = false
      for(let item of this.realAnswers){
        if(Boolean(item.active) === true){
          valid = true
          break
        }
      }
      if(!valid){
        this.$toast('你还未完整填写信息哦~',2000)
        return
      }
      this.currentStep = Number(this.currentStep) + 1
    }
  },
  computed: {
    ...mapState(['questionareList']),
    totalStep(){
      return this.questionareList.length || 0
    },
    firstAnswerList(){
      return this.totalStep ? this.questionareList[0].answers || [] : []
    },
    realTitle(){
      if(!this.totalStep){
        return []
      }
      return this.questionareList[Number(this.currentStep)].title
    },
    realMultiSelect(){
      if(!this.totalStep){
        return false
      }
      return this.questionareList[Number(this.currentStep)].multiSelect
    },
    realAnswers(){
       if(!this.totalStep){
        return false
      }
      return this.questionareList[Number(this.currentStep)].answers
    },
    chunkAnswers(){
      return this.chunk(this.realAnswers, 2)
    },
    stepWidth(){
      return (12 / Number(this.totalStep)) * (Number(this.currentStep) + 1)
    },
    firstStep(){
      return Number(this.currentStep) === 0 
    },
    lastStep(){
      return (Number(this.currentStep) + 1) === Number(this.totalStep)
    },
    nextVisible(){
      if(!this.firstStep && !this.lastStep){
        return true
      }
      return false
    }
  }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "questionare_container" }, [
    _c(
      "div",
      { staticClass: "questionare ques-bg" },
      [
        _c("div", { staticClass: "top" }, [
          _c(
            "p",
            {
              staticClass: "just-look",
              attrs: { "data-track": "Questionaire_stroll" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.justLook.apply(null, arguments)
                }
              }
            },
            [_vm._v("随便逛逛")]
          ),
          _vm.totalStep
            ? _c("div", { staticClass: "progress" }, [
                _c("section", {
                  staticClass: "step",
                  style: "width:" + _vm.stepWidth + "rem"
                })
              ])
            : _vm._e(),
          _vm.firstStep
            ? _c("p", { staticClass: "just-look" })
            : _c(
                "p",
                {
                  staticClass: "just-look",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.prev.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("上一步")]
              )
        ]),
        _vm._l(_vm.realTitle, function(item, index) {
          return _c("p", { key: "ques" + index, staticClass: "title" }, [
            _vm._v(_vm._s(item))
          ])
        }),
        _vm._m(0),
        _c(
          "div",
          { staticClass: "trans-wrap" },
          [
            _vm.firstStep
              ? _c(
                  "div",
                  { staticClass: "ques-list" },
                  _vm._l(_vm.firstAnswerList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: "ques" + index,
                        staticClass: "ques-item",
                        on: {
                          click: function($event) {
                            return _vm.selectQuestion(item.value, index)
                          }
                        }
                      },
                      [
                        _c("p", { staticClass: "title" }, [
                          _vm._v(_vm._s(item.label))
                        ]),
                        _c("div", { staticClass: "select-icon" }),
                        item.tips
                          ? _c("div", { staticClass: "tag" }, [
                              _vm._v(_vm._s(item.tips))
                            ])
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "insurance" }, [
                  _c("div", { staticClass: "insurance-bg" }, [
                    _c("div", { staticClass: "ques-title" }, [
                      _vm._v(
                        "您可以选择" +
                          _vm._s(_vm.realMultiSelect ? "多" : "单") +
                          "个保险类型哦"
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "insurance-list" },
                    _vm._l(_vm.chunkAnswers, function(lineItem, lineIndex) {
                      return _c(
                        "div",
                        { key: "line" + lineIndex, staticClass: "row" },
                        _vm._l(lineItem, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: "an" + index,
                              staticClass: "insurance-item",
                              class: { active: item.active || false },
                              on: {
                                click: function($event) {
                                  return _vm.selectAnswer(
                                    lineIndex,
                                    index,
                                    item.active
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(" " + _vm._s(item.label) + " "),
                              item.tips
                                ? _c("div", { staticClass: "tip" }, [
                                    _vm._v(_vm._s(item.tips))
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "select" }, [
                                item.active
                                  ? _c("img", {
                                      staticClass: "select-icon",
                                      attrs: {
                                        src: require("@/assets/img/ok.png"),
                                        alt: ""
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    }),
                    0
                  )
                ]),
            _vm._l(_vm.firstAnswerList, function(item, index) {
              return [
                _c("img", {
                  key: "pic" + index,
                  class: [
                    _vm.firstStep ? "before-animation" : "after-animation",
                    "animation-pic",
                    _vm.getTransition(item.value, index)
                  ],
                  style:
                    "top:" +
                    _vm.getTopValue(index) +
                    "rem;opacity:" +
                    _vm.getOpacity(item.value) +
                    ";",
                  attrs: {
                    src: require("@/assets/img/" + item.value + ".png"),
                    alt: item.label
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectQuestion(item.value, index)
                    }
                  }
                })
              ]
            })
          ],
          2
        ),
        _vm.nextVisible
          ? _c(
              "div",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.next.apply(null, arguments)
                  }
                }
              },
              [_vm._v("下一步")]
            )
          : _vm._e(),
        _vm.lastStep
          ? _c(
              "div",
              {
                staticClass: "btn",
                attrs: { "data-track": "Questionaire_submit" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.confirm.apply(null, arguments)
                  }
                }
              },
              [_vm._v("提交完成推荐")]
            )
          : _vm._e(),
        _c("p", { staticClass: "tips" }, [
          _vm._v("*您所填写的信息都将严格保密")
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "desc" }, [
      _vm._v(" 填写信息，获取"),
      _c("span", [_vm._v("智能推荐")]),
      _vm._v("方案 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
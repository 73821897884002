var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.welfare.img
    ? _c("div", { staticClass: "welfare" }, [
        _c(
          "a",
          { attrs: { href: _vm.welfare.url, "data-track": _vm.welfare.track } },
          [_c("img", { attrs: { src: _vm.welfare.img, alt: "" } })]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
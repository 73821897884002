import { render, staticRenderFns } from "./scroll.vue?vue&type=template&id=090b9747&scoped=true&"
import script from "./scroll.vue?vue&type=script&lang=js&"
export * from "./scroll.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./scroll.vue?vue&type=style&index=0&id=090b9747&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "090b9747",
  "0c262116"
  
)

component.options.__file = "src/views/common/scroll.vue"
export default component.exports
import { render, staticRenderFns } from "./concern.vue?vue&type=template&id=13395cc1&"
import script from "./concern.vue?vue&type=script&lang=js&"
export * from "./concern.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "19879c52"
  
)

component.options.__file = "src/views/components/concern.vue"
export default component.exports
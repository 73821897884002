var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-list" },
    [
      _vm._l(_vm.list, function(item, index) {
        return [
          Number(item.channelRecommend) === 1 || Number(_vm.classId) === 0
            ? _c(
                "div",
                {
                  key: "product" + index,
                  staticClass: "item-wrap product-item-wrap"
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "product-item",
                      attrs: {
                        href: item.src,
                        "data-index": item.class,
                        "data-id": item.productId,
                        "data-classid": item.insuranceClassify.id,
                        "data-track":
                          "index_buy_new" + _vm.getTrackIndex(index + 1)
                      }
                    },
                    [
                      _c("div", { staticClass: "product-item-cover" }, [
                        _c("img", {
                          staticClass: "cover-img",
                          attrs: { src: item.pic2, alt: "" }
                        }),
                        String(_vm.isNew) === "1" &&
                        String(item.recommendForNew) === "1"
                          ? _c(
                              "i",
                              { staticClass: "product-item-shine new-yellow" },
                              [_vm._v("新人优选")]
                            )
                          : item.hot
                          ? _c("i", { staticClass: "product-item-shine red" }, [
                              _vm._v("热卖")
                            ])
                          : item.isnew
                          ? _c(
                              "i",
                              { staticClass: "product-item-shine green" },
                              [_vm._v("新品")]
                            )
                          : item.customPic
                          ? _c("img", {
                              staticClass: "label-pic",
                              attrs: { src: item.customPic, alt: "" }
                            })
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "product-item-content" }, [
                        _c("div", { staticClass: "detail" }, [
                          _c(
                            "h3",
                            { staticClass: "title product-title" },
                            [
                              item.diyTag
                                ? [
                                    Number(item.diyTag) === 1
                                      ? _c("img", {
                                          staticClass: "product_tag",
                                          attrs: {
                                            src: require("@/assets/img/djlabel.png"),
                                            alt: "小雨伞保险"
                                          }
                                        })
                                      : _vm._e(),
                                    Number(item.diyTag) === 2
                                      ? _c("img", {
                                          staticClass: "product_tag",
                                          attrs: {
                                            src: require("@/assets/img/dzlabel.png"),
                                            alt: "小雨伞保险"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                : _vm._e(),
                              _vm._v(" " + _vm._s(item.title) + " ")
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "product-desc" },
                            _vm._l(item.advantage, function(
                              descItem,
                              descIndex
                            ) {
                              return _c(
                                "section",
                                { key: "desc" + descIndex },
                                [_vm._v(_vm._s(descItem))]
                              )
                            }),
                            0
                          ),
                          item.tagList && item.tagList.length
                            ? _c(
                                "div",
                                { staticClass: "tag_wrap" },
                                _vm._l(item.tagList, function(
                                  tagItem,
                                  tagIndex
                                ) {
                                  return _c(
                                    "section",
                                    {
                                      key: "tag" + tagIndex,
                                      class: [
                                        "yellow",
                                        "" + tagItem.class,
                                        "tag_item"
                                      ]
                                    },
                                    [_vm._v(" " + _vm._s(tagItem.text) + " ")]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "func product-func" }, [
                          _c(
                            "span",
                            { staticClass: "price-value product-price-value" },
                            [_vm._v(_vm._s(item.itemPrice))]
                          ),
                          _c("div", { staticClass: "price-common" }, [
                            _c("p", { staticClass: "price-desc" }, [
                              _c("span", [_vm._v(_vm._s(item.priceUnit))]),
                              item.isBottomPrice
                                ? _c(
                                    "span",
                                    { staticClass: "lowest-price-tip" },
                                    [_vm._v("详见费率表")]
                                  )
                                : _vm._e()
                            ]),
                            _c("div", { staticClass: "price-common-right" }, [
                              item.commentTip && Number(item.commentTip) > 0
                                ? _c("span", { staticClass: "comment" }, [
                                    _vm._v(_vm._s(item.commentTip) + "条评价")
                                  ])
                                : _vm._e(),
                              _c("i", { staticClass: "icon-arrow" })
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  item.recommendInfo
                    ? _c(
                        "div",
                        { staticClass: "recommend product-recommend" },
                        [
                          _c("div", { staticClass: "recommend-img" }, [
                            _c("img", {
                              attrs: { src: item.recommendInfo.picUrl, alt: "" }
                            })
                          ]),
                          _c("img", {
                            staticClass: "recommend-rightBg",
                            attrs: {
                              src: require("@/assets/img/recommend-rightBg.png"),
                              alt: ""
                            }
                          }),
                          _c("div", { staticClass: "recommend-desc" }, [
                            _vm._v(_vm._s(item.recommendInfo.recommendWord))
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
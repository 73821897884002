<template>
  <div class="login_layer" v-if="!isLogin">
    <section class="login_config">
      <img :src="loginTips.icon || require('@/assets/img/logo.png')" alt="" class="avatar">
      <p class="text">{{ loginTips.desc || "新人福利：3万元保额意外保障"}}</p>
    </section>
    <section class="btn" data-track='login_new00' @click.stop="showLoginLayer">{{loginTips.btn || "立即登录"}}</section>
  </div>
</template>

<script>
// import { Login } from '@xysfe/actui'
import Vue from 'vue'
export default {
  name: 'LoginLayer',
  props:{
    isLogin: {
      type: Boolean,
      default: false
    },
    loginTips:{
      type: Object,
      default:()=>{
        return {}
      }
    },
    isWx:{
      type: Boolean,
      default:false
    }
  },
  data(){
    return {
      btnLock:false
    }
  },
  methods:{
    showLoginLayer(){
      if(this.isWx){
        window._xystj && window._xystj.trackEvent('login_new05')
      }else{
        window._xystj && window._xystj.trackEvent('login_new01', 'display')
        window._xystj && window._xystj.trackEvent('login_new041')
      }
      
      if(this.btnLock){
        return false
      }
      this.btnLock = true
      if(window.$Login){
        window.$Login({ Vue, 
          param: { 
            platform: 0, 
            icon: this.loginTips.icon,
            desc: this.loginTips.desc,
            bottom: 60
          }, 
          h5ShowLayer: true, 
          zIndex: 998
        }).then(() => {
          this.btnLock = false
          this.$emit('success')
        }).catch(() => {
          this.btnLock = false
          // console.log('登录取消')
        })
      }
    }
  }
}
</script>
<template>
  <div class="layer">
    <div class="activity">
      <section class="activity_close" :data-track="data.closeTrack" @click="close">
        <img src="@/assets/img/icon/icon-close.png">
      </section>
      <a :href="data.url || 'javascript:;'" :data-track="data.layerTrack" @click.stop="layerClick">
        <img :src="data.src" alt="" class="activity_img">
      </a>
      <!-- 生日弹窗内容 -->
      <div class="birthday" v-if="data.birthdayVisible">
        <div class="avatar"><img alt="" :src="data.headurl"></div>
        <div class="text">
          <div>亲爱的<span>{{data.level}}</span>会员</div>
          <div>今天是<span class="truename">{{data.truename}}</span>的生日</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PopLayer",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  methods:{
    layerClick(){
      this.$emit('layerclick')
    },
    close(){
      this.$emit('close')
    }
  }
}
</script>
<template>
  <div class="finance-wrapper">
    <template v-for='(financeItem,financeIndex) in list'>
      <div class="finance-list"  :key="`finance${financeIndex}`" >
        <!-- v-if="financeIndex !== 0" -->
        <template v-if="financeItem.productList.length">
          <div class="finance-nav">
            <div class="title">{{financeItem.text}}</div>
            <div class="desc">{{financeItem.desc}}</div>
          </div>
          <div class="finance-product-list">
            <template v-for='(item,index) in financeItem.productList'>
              <div class="item-wrap finance-item-wrap"  :key="`product-${index}`" v-if="Number(item.channelRecommend) === 1">
                <a class="product-item" 
                  :href="item.src" 
                  :data-id="item.productId"
                  :data-classid="item.insuranceClassify.id"
                  :data-track="`buy_new2020${getTrackIndex(index+1)}`">
                  <div class="product-item-cover">
                    <img :src="item.pic2" alt="" class="cover-img">
                    <i class="finance-item-shine new-yellow" v-if="String(isNew) === '1' && String(item.recommendForNew) === '1'">新人优选</i>
                    <i class="finance-item-shine red" v-else-if="item.hot">热卖</i>
                    <i class="finance-item-shine green" v-else-if="item.isnew">新品</i>
                    <img :src="item.customPic" alt="" v-else-if="item.customPic" class="label-pic">
                    <i class="product-video" v-if="item.videourl"></i>
                  </div>
                  <div class="product-item-content">
                    <div class="detail">
                      <h3 class="title finance-title">
                        <template v-if="item.diyTag">
                          <img src="@/assets/img/djlabel.png" alt="小雨伞保险" class="product_tag" v-if="Number(item.diyTag) === 1">
                          <img src="@/assets/img/dzlabel.png" alt="小雨伞保险" class="product_tag" v-if="Number(item.diyTag) === 2">
                        </template>
                        {{item.title}}
                      </h3>
                      <div class="finance-desc">
                        <section v-for="(descItem,descIndex) in item.advantage" :key="`desc${descIndex}`">{{descItem}}</section>
                      </div>
                      <div class="tag_wrap finance_tag_wrap" v-if="item.tagList && item.tagList.length">
                        <section v-for='(tagItem,tagIndex) in item.tagList' :key="`tag${tagIndex}`"  class="tag_item yellow">
                          {{tagItem.text}}
                        </section>
                      </div>
                    </div>
                    <div class="func finance-func">
                      <span class="price-value finance-price-value">{{item.itemPrice}}</span>
                      <div class="price-common">
                        <p class="price-desc">
                          <span>{{item.priceUnit}}</span>
                          <span v-if="item.isBottomPrice" class="lowest-price-tip">详见费率表</span>
                        </p>
                        <div>
                          <span class="comment" v-if="item.commentTip && Number(item.commentTip)> 100">{{ item.commentTip }}条评价</span>
                          <i class="icon-arrow"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <div class="recommend finance-recommend" v-if="item.recommendInfo">
                  <div class="recommend-img">
                    <img :src="item.recommendInfo.picUrl" alt="">
                  </div>
                  <div class="recommend-desc">{{item.recommendInfo.recommendWord}}</div>
                  <img class="recommend-rightBg" src="@/assets/img/recommend-rightBg.png" alt="">
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ProductFinance',
  props: {
    list: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  computed: {
    ...mapState(['isNew'])
  },
  methods:{
    getTrackIndex(index){
      return index < 10 ? `0${index}`:index
    }
  }
}
</script>